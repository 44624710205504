import {
  kvTableTemplateStatus,
  kvOrganizationType,
  kvIsEntrust,
  kvIsAuthentication,
  kvGrantType,
  kvContractMode,
  kvAccountType,
  kvTemplateType,
  sourceType,
  signType,
  parkBM,
  kvSyncInvoiceType,
  kvRoleEenable,
  authorization,
  kvNoticeType,
  kvNoticeIssueMode,
  kvNoticeIssueState,
  agreementType,
  kvOpenMode
} from './formatters'
import { regular } from './validate'

// 表单模版
export const formTemplate = [
  {
    cmp: 'input',
    label: '输入框1',
    prop: 'xxa',
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    }
  },
  {
    cmp: 'select',
    label: '选择框1',
    prop: 'xxc',
    options: kvTableTemplateStatus
  },
  { cmp: 'dtpickers', label: '时选框1', prop: ['xxd1', 'xxd2'] },
  {
    cmp: 'upload',
    label: '上传',
    prop: 'xxe',
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    }
  }
]

// 系统-用户管理
export const formOperationUser = [
  {
    cmp: 'input',
    label: '用户名称',
    prop: 'userName',
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '登录账号',
    prop: 'loginUser',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        { pattern: regular.enNum, message: regular.enNumMsg, trigger: 'blur' }
      ]
    },
    disabled: `form.userId`
  },
  {
    cmp: 'input',
    label: '联系方式',
    prop: 'mobile',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { maxlength: 11 }
  },
  {
    cmp: 'select',
    label: '关联角色',
    prop: 'roleId',
    options: [],
    itemConfig: { rules: [{ required: true, message: '必选', trigger: 'change' }] }
  }
]

// 修改用户密码
export const formUpdateOperationUserPwd = [
  {
    cmp: 'input',
    label: '旧密码',
    prop: 'wornPwd',
    itemConfig: {
      rules: [{ required: true, message: '请输入旧密码', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '新密码',
    prop: 'newPwd',
    itemConfig: {
      rules: [{ required: true, message: '请输入密码', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '确认新密码',
    prop: 'newPwd2',
    itemConfig: {
      rules: [{ required: true, message: '请再次输入密码', trigger: 'blur' }]
    },
    config: { placeholder: '请再次输入新密码' }
  }
]

// 新建税源地
export const formCreatPark = [
  // 基本信息
  {
    sessionTitle: '基本信息',
    cmp: 'input',
    label: '税源地编号',
    prop: 'supplierCode',
    col: 11,
    config: { disabled: true, placeholder: '系统生成' }
  },
  {
    cmp: 'input',
    label: '税源地名称',
    prop: 'supplierName',
    col: 11,
    // disabled: `form.isEdit`,
    itemConfig: {
      rules: [{ required: true, message: '请输入税源地名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '联系人',
    prop: 'linkPerson',
    col: 11,
    config: { placeholder: '请输入联系人姓名' },
    itemConfig: {
      rules: [{ required: true, message: '请输入联系人', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'linkTel',
    col: 11,
    config: { placeholder: '请输入联系人手机号', maxlength: 11 },
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    }
  },
  {
    cmp: 'radio',
    label: '类型',
    prop: 'supplierType',
    col: 11,
    options: signType,
    disabled: `form.isEdit||form.isAuthentication==1`,
    itemConfig: {
      rules: [{ required: true, message: '请选择类型', trigger: 'change' }]
    }
  },
  {
    cmp: 'radio',
    label: '业务模式',
    prop: 'businessType',
    col: 11,
    options: parkBM,
    disabled: `form.isEdit||form.isAuthentication==1`,
    itemConfig: {
      rules: [{ required: true, message: '请选择业务模式', trigger: 'change' }]
    }
  },

  // 企业主体
  {
    sessionTitle: '企业主体',
    cmp: 'input',
    label: '认证企业',
    prop: 'mainName',
    col: 11,
    config: { placeholder: '请输入企业名称' },
    // show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1||form.isMainDisabled==1`,
    itemConfig: {
      rules: [{ required: true, message: '请输入企业名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '社会统一信用代码',
    prop: 'creditCode',
    col: 11,
    disabled: `form.isEdit`,
    itemConfig: {
      rules: [{ required: true, message: '请输入社会统一信用代码', trigger: 'blur' }]
    }
  },
  {
    cmp: 'upload',
    label: '营业执照照片',
    prop: 'businessLicense',
    col: 11
    // show: `!!form.creditCode`
    // disabled: `form.isEdit||form.isAuthentication==1`
  },
  {
    cmp: 'select',
    label: '认证状态',
    prop: 'isAuthentication',
    col: 11,
    options: kvIsAuthentication,
    config: { disabled: true, placeholder: '未认证' }
  },
  {
    cmp: 'select',
    label: '授权状态',
    prop: 'authorizationType',
    col: 11,
    options: authorization,
    config: { disabled: true, placeholder: '未授权' }
  },
  // 开户企业
  {
    sessionTitle: '开户企业',
    cmp: 'input',
    col: 11,
    label: '认证企业',
    prop: 'expeMainName',
    show: `form.supplierType==2`,
    config: { placeholder: '请输入企业名称' },
    disabled: `form.isEdit||form.expeIsAuthentication==1||form.secondaryDisbled == 1`,
    itemConfig: {
      rules: [
        {
          required: true,
          message: '认证企业必填',
          trigger: 'blur'
        }
      ]
    }
  },
  {
    cmp: 'input',
    col: 11,
    label: '社会统一信用代码',
    prop: 'expeCreditCode',
    show: `form.supplierType==2`,
    disabled: `form.isEdit`,
    itemConfig: {
      rules: [
        {
          required: true,
          message: '认证企业必填',
          trigger: 'blur'
        }
      ]
    }
  },
  {
    cmp: 'upload',
    col: 11,
    label: '营业执照照片',
    prop: 'expeBusinessLicense',
    disabled: `form.isEdit||form.expeIsAuthentication==1`,
    show: `form.supplierType==2`
  },
  {
    cmp: 'select',
    col: 11,
    label: '认证状态',
    prop: 'expeIsAuthentication',
    show: `form.supplierType==2`,
    options: kvIsAuthentication,
    config: { disabled: true, placeholder: '未认证' }
  },
  {
    cmp: 'select',
    col: 11,
    label: '授权状态',
    prop: 'expeIsAuthentication',
    show: `form.supplierType==2`,
    options: authorization,
    config: { disabled: true, placeholder: '未认证' }
  },
  // 开户信息
  {
    cmp: 'slot',
    sessionTitle: '开户信息',
    name: 'openInfo'
  },
  // 风控设置
  {
    sessionTitle: '风控设置',
    cmp: 'select',
    label: '可开票类型',
    prop: 'syncInvoiceType',
    options: kvSyncInvoiceType,
    itemConfig: {
      rules: [{ required: true, message: '请选择可开票类型', trigger: 'change' }]
    },
    config: {
      multiple: true,
      style: { width: '300px' }
    }
  },
  {
    cmp: 'upload',
    label: '业务确认单模板',
    prop: 'confirmReceiptsTemplateurl',
    suffixArray: ['doc', 'docx'],
    col: 22
  },
  {
    cmp: 'radio',
    label: '确认方式',
    prop: 'confirmReceiptsMode',
    options: [
      { key: 0, label: '线上确认（无需线下盖章）' },
      { key: 1, label: '盖章确认（需线下盖章并上传盖章文件）' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择确认方式', trigger: 'change' }]
    }
  },
  {
    cmp: 'slot',
    name: 'taxTable'
  },
  // 其他信息
  {
    sessionTitle: '其他信息',
    cmp: 'radio',
    label: '支持创客签约',
    prop: 'isPeopleSign',
    options: [
      { key: 0, label: '否' },
      { key: 1, label: '是' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择是否支持创客签约', trigger: 'change' }]
    }
  },
  {
    cmp: 'radio',
    label: '数据同步',
    prop: 'isSync',
    options: [
      { key: 0, label: '关闭' },
      { key: 1, label: '开启' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择是否开启数据同步', trigger: 'change' }]
    }
  },
  {
    cmp: 'input',
    label: '同步配置',
    prop: 'syncConfig',
    col: 22,
    show: `!!form.isSync`,
    config: { type: 'textarea', placeholder: '请输入同步配置' },
    itemConfig: {
      rules: [{ required: true, message: '请输入同步配置', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    col: 22,
    // show: `!!form.creditCode`,
    // disabled: `form.isEdit||form.isAuthentication==1`,
    config: { type: 'textarea', maxlength: 200, placeholder: '请输入备注,不超过200字' }
  }
]

// 新建通道
export const formChannel = [
  {
    cmp: 'input',
    label: '通道编号',
    prop: 'channelCode',
    config: { disabled: true, placeholder: '系统生成' }
  },
  {
    cmp: 'input',
    label: '通道名称',
    prop: 'channelName',
    itemConfig: {
      rules: [{ required: true, message: '请输入通道名称', trigger: 'blur' }]
    }
  },
  // {
  //   cmp: 'select',
  //   label: '发放方式',
  //   prop: 'grantType',
  //   options: kvGrantType,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择发放方式', trigger: 'change' }]
  //   }
  // },
  {
    cmp: 'input',
    label: '信息配置',
    prop: 'channelConfig',
    config: { type: 'textarea' },
    itemConfig: {
      rules: [{ required: true, message: '请输入信息配置', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'channelRemark',
    config: { type: 'textarea', maxlength: 200 }
  }
]

// 新建个体户商户
export const formCreatSelfMerchant = [
  {
    cmp: 'input',
    label: '商户号',
    col: 11,
    config: { disabled: true, placeholder: '系统生成' }
  },
  {
    cmp: 'input',
    label: '登录账号',
    prop: 'loginName',
    col: 11,
    disabled: `form.isEdit`,
    itemConfig: {
      rules: [{ required: true, message: '请输入登录账号', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '商户名称',
    prop: 'merchantName',
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请输入商户名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'mobile',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { maxlength: 11 }
  },
  {
    cmp: 'input',
    label: '社会统一信用代码',
    prop: 'creditCode',
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请输入社会统一信用代码', trigger: 'blur' }]
    }
  },
  // {
  //   cmp: "select",
  //   label: "认证状态",
  //   prop: "isAuthentication",
  //   col: 11,
  //   options: kvIsAuthentication,
  //   config: { disabled: true, placeholder: "未认证" },
  // },
  // 2层显示
  // 需要修改字段
  {
    cmp: 'select',
    label: '来源',
    prop: 'source',
    col: 11,
    options: sourceType,
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`
  },
  {
    cmp: 'input',
    label: '归属通道',
    prop: 'attributionChannel',
    col: 11,
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`,
    itemConfig: {
      rules: [
        {
          min: 0,
          max: 50,
          message: '归属通道不能超过50个字符',
          trigger: 'blur'
        }
      ]
    }
  },
  {
    cmp: 'input',
    label: '联系人',
    prop: 'contract',
    col: 11,
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`,
    itemConfig: {
      rules: [
        {
          min: 0,
          max: 50,
          message: '联系人不能超过50个字符',
          trigger: 'blur'
        }
      ]
    }
  },
  {
    cmp: 'input',
    label: '联系方式',
    prop: 'contractPhone',
    itemConfig: {
      rules: [
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    col: 11,
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`
  },
  // {
  //   cmp: "select",
  //   label: "组织类型",
  //   prop: "organizationType",
  //   col: 11,
  //   options: kvOrganizationType,
  //   show: `!!form.creditCode`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  // },
  {
    cmp: 'input',
    label: '企业名称',
    prop: 'mainName',
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请输入企业名称', trigger: 'blur' }]
    },
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`
  },

  {
    cmp: 'upload',
    label: '营业执照照片',
    prop: 'businessLicense',
    col: 11,
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`
  },
  // {
  //   cmp: "radio",
  //   label: "实际操作人",
  //   prop: "isEntrust",
  //   col: 11,
  //   options: kvIsEntrust,
  //   show: `!!form.creditCode`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  // },
  // 法人
  // {
  //   cmp: "input",
  //   label: "法人姓名",
  //   prop: "juridicalName",
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==0`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  // },
  // {
  //   cmp: "input",
  //   label: "法人手机号",
  //   prop: "juridicalTel",
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==0`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  //   itemConfig: {
  //     rules: [
  //       {
  //         pattern: regular.mobile,
  //         message: regular.mobileMsg,
  //         trigger: "blur",
  //       },
  //     ],
  //   },
  //   config: { maxlength: 11 },
  // },
  // {
  //   cmp: "input",
  //   label: "法人身份证号",
  //   prop: "juridicalIdcard",
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==0`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  //   itemConfig: {
  //     rules: [
  //       {
  //         pattern: regular.IDCard,
  //         message: regular.IDCardMsg,
  //         trigger: "blur",
  //       },
  //     ],
  //   },
  //   config: { maxlength: 18 },
  // },
  // {
  //   cmp: "upload",
  //   label: "法人身份证正面照片",
  //   prop: "juridicalIdcardFront",
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==0`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  // },
  // {
  //   cmp: "upload",
  //   label: "法人身份证反面照片",
  //   prop: "juridicalIdcardBack",
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==0`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  // },
  // 委托人
  // {
  //   cmp: 'input',
  //   label: '委托代理人姓名',
  //   prop: 'consignorName',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`
  // },
  // {
  //   cmp: 'input',
  //   label: '委托代理人身份证号',
  //   prop: 'consignorIdcard',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  //   itemConfig: {
  //     rules: [
  //       {
  //         pattern: regular.IDCard,
  //         message: regular.IDCardMsg,
  //         trigger: 'blur'
  //       }
  //     ]
  //   },
  //   config: { maxLength: 18 }
  // },
  // {
  //   cmp: 'upload',
  //   label: '委托代理人身份证正面照片',
  //   prop: 'consignorIdcardFront',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`
  // },
  // {
  //   cmp: 'upload',
  //   label: '委托代理人身份证反面照片',
  //   prop: 'consignorIdcardBack',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`
  // },
  // {
  //   cmp: 'input',
  //   label: '委托代理人手机号',
  //   prop: 'consignorTel',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`,
  //   itemConfig: {
  //     rules: [
  //       {
  //         pattern: regular.mobile,
  //         message: regular.mobileMsg,
  //         trigger: 'blur'
  //       }
  //     ]
  //   },
  //   config: { maxLength: 11 }
  // },
  // {
  //   cmp: 'upload',
  //   label: '代理人授权委托书',
  //   prop: 'consignorAuthorization',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`
  // },
  // {
  //   cmp: 'input',
  //   label: '企业对公账户名称',
  //   prop: 'accountName',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`
  // },
  // {
  //   cmp: 'input',
  //   label: '企业对公开户银行',
  //   prop: 'accountBank',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`
  // },
  // {
  //   cmp: 'input',
  //   label: '企业对公银行账户',
  //   prop: 'accountNumber',
  //   col: 11,
  //   show: `!!form.creditCode&&form.isEntrust==1`,
  //   disabled: `form.isEdit||form.isAuthentication==1`
  // },

  // other
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    col: 22,
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`,
    config: { type: 'textarea', maxlength: 200 }
  }
]

// 配置管理-充值账户-新增表单
export const formsMerchantAccount = [
  {
    cmp: 'input',
    label: '账户名称',
    prop: 'accountName',
    itemConfig: {
      rules: [{ required: true, message: '请输入账户名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '开户银行',
    prop: 'accountBank',
    itemConfig: {
      rules: [{ required: true, message: '请输入开户银行', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '银行账户',
    prop: 'accountNumber',
    itemConfig: {
      rules: [{ required: true, message: '请输入银行账户', trigger: 'blur' }]
    }
  },
  {
    cmp: 'select',
    label: '账户类型',
    prop: 'accountType',
    options: kvAccountType,
    itemConfig: {
      rules: [{ required: true, message: '请选择账户类型', trigger: 'change' }]
    }
  }
]

// 合同模板
export const formContract = [
  {
    cmp: 'input',
    label: '模板编号',
    prop: 'templateCode',
    config: { placeholder: '请输入模板编号与法大大中配置一致' },
    itemConfig: {
      rules: [{ required: true, message: '请输入模板编号', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '模板名称',
    prop: 'templateName',
    itemConfig: {
      rules: [{ required: true, message: '请输入模板名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'radio',
    label: '签约方式',
    prop: 'templateSignType',
    options: kvContractMode.filter((val) => {
      return val.key != 10
    }),
    itemConfig: {
      rules: [{ required: true, message: '请选签约方式', trigger: 'change' }]
    },
    config: { disabled: false }
  },
  {
    cmp: 'radio',
    label: '模板类型',
    prop: 'templateType',
    options: [
      { key: 1, label: '企业/个体户', disabled: false },
      { key: 3, label: '个人' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择模板类型', trigger: 'change' }]
    },
    config: { disabled: false }
  },
  // {
  //   cmp: 'select',
  //   label: '模板类型',
  //   prop: 'templateType',
  //   options: kvTemplateType,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择模板类型', trigger: 'change' }]
  //   }
  // },
  {
    cmp: 'upload',
    label: '模板文件',
    prop: 'templatePath',
    suffixArray: ['pdf'],
    config: { tips: '只能上传pdf文件' },
    itemConfig: {
      rules: [{ required: true, message: '请上传模板文件', trigger: 'blur' }]
    },
    show: 'form.templateSignType==30'
  },
  {
    cmp: 'input',
    config: { type: 'textarea', rows: '3' },
    label: '备注',
    prop: 'templateRemark'
  }
]

// 上传合同
export const uploadContractForm = [
  {
    cmp: 'input',
    label: '合同名称',
    prop: 'contractName',
    itemConfig: {
      'label-width': '120px',
      rules: [{ required: true, message: '请输入合同名称', trigger: 'blur' }]
    },
    show: 'form.signType==1'
  },
  {
    cmp: 'upload',
    label: '合同文件',
    prop: 'contractUrl',
    config: { tips: '只能上传jpg/png/jpeg/pdf/xlsx/xls/zip/rar文件' },
    suffixArray: ['jpg', 'jpeg', 'png', 'pdf', 'xlsx', 'xls', 'zip', 'rar'],
    itemConfig: {
      'label-width': '120px',
      rules: [{ required: true, message: '请输入合同文件', trigger: 'blur' }]
    },
    show: 'form.signType==1'
  },
  {
    cmp: 'upload',
    label: '协议文件',
    prop: 'contractUrl',
    config: { tips: '只能上传jpg/png/jpeg/pdf/xlsx/xls/zip/rar文件' },
    suffixArray: ['jpg', 'jpeg', 'png', 'pdf', 'xlsx', 'xls', 'zip', 'rar'],
    itemConfig: {
      'label-width': '120px',
      rules: [{ required: true, message: '请输入协议文件', trigger: 'blur' }]
    },
    show: 'form.signType==2'
  },
  {
    cmp: 'dpicker',
    label: '签约时间',
    prop: 'contractTime',
    config: { type: 'datetime', 'value-format': 'yyyy-MM-dd HH:mm:ss' },
    itemConfig: {
      'label-width': '120px',
      rules: [{ required: true, message: '请输入签约时间', trigger: 'blur' }]
    }
  }
]
// 新建商户
export const formPeolple = [
  {
    cmp: 'input',
    label: '创客姓名',
    prop: 'userName',
    col: 11,
    disabled: `!!form.id`,
    itemConfig: {
      rules: [{ required: true, message: '请输入创客姓名', trigger: 'blur' }]
    },
    config: { placeholder: '请输入创客姓名' }
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'mobile',
    disabled: `!!form.id`,
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { placeholder: '请输入手机号', maxlength: 11 }
  },
  {
    cmp: 'input',
    label: '证件号',
    disabled: `!!form.id`,
    prop: 'idCard',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入身份证号', trigger: 'blur' },
        {
          pattern: regular.IDCard,
          message: regular.IDCardMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { placeholder: '请输入身份证号' }
  },
  {
    cmp: 'input',
    label: '银行卡号',
    disabled: `!!form.id`,
    prop: 'bankNumber',
    col: 11,
    config: { placeholder: '请输入银行卡号' }
  },
  {
    cmp: 'input',
    disabled: `!!form.id`,
    label: '支付宝',
    prop: 'alipayNumber',
    col: 11,
    config: { placeholder: '请输入支付宝号' }
  },
  {
    cmp: 'select',
    label: '所属商户',
    disabled: `!!form.id`,
    prop: 'mermchantId',
    options: [],
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      placeholder: '请输入商户编号/商户名称',
      filterable: true,
      style: { width: '100%' }
    }
  },
  {
    cmp: 'upload',
    label: '身份证正面照片',
    disabled: `!!form.id`,
    prop: 'idFontPhoto',
    col: 11
  },
  {
    cmp: 'upload',
    disabled: `!!form.id`,
    label: '身份证反面照片',
    prop: 'idBackPhoto',
    col: 11
  },
  {
    cmp: 'multipleUpload',
    label: '其他文件',
    disabled: `!!form.id`,
    prop: 'attachList',
    col: 11
  },
  // other
  {
    cmp: 'input',
    label: '备注',
    disabled: `!!form.id`,
    prop: 'remark',
    col: 22,
    config: { type: 'textarea', maxlength: 200 }
  }
]
// 新黑名单
export const formBlackPeolple = [
  {
    cmp: 'input',
    label: '创客姓名',
    prop: 'userName',
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请输入创客姓名', trigger: 'blur' }]
    },
    config: { placeholder: '请输入创客姓名' }
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'mobile',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { placeholder: '请输入手机号', maxlength: 11 }
  },
  {
    cmp: 'input',
    label: '证件号',
    prop: 'idCard',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入身份证号', trigger: 'blur' },
        {
          pattern: regular.IDCard,
          message: regular.IDCardMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { placeholder: '请输入身份证号' }
  },
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'mermchantId',
    options: [],
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      placeholder: '请输入商户编号/商户名称',
      filterable: true,
      style: { width: '100%' }
    }
  },
  // other
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    col: 22,
    config: { type: 'textarea', maxlength: 200 }
  }
]

// 角色
export const formRole = [
  {
    cmp: 'input',
    label: '角色名称',
    prop: 'roleName',
    itemConfig: {
      rules: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'radio',
    label: '角色状态',
    prop: 'isEenable',
    options: kvRoleEenable,
    itemConfig: {
      rules: [{ required: true, message: '请选择角色状态', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'roleDesc',
    config: { type: 'textarea', maxlength: 200 }
  },
  { label: '权限' }
]
// 公告
export const formNotice = [
  {
    cmp: 'select',
    label: '公告类型',
    prop: 'messageType',
    options: kvNoticeType,
    itemConfig: {
      rules: [{ required: true, message: '请选择公告类型', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '公告名称',
    prop: 'messageTitle',
    itemConfig: {
      rules: [{ required: true, message: '请输入公告名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '公告内容',
    prop: 'messageContent',
    itemConfig: {
      rules: [{ required: true, message: '请输入公告内容', trigger: 'blur' }]
    },
    config: { type: 'textarea', maxlength: 1000, rows: 5, showWordLimit: true }
  },
  {
    cmp: 'upload',
    label: '附件',
    prop: 'attachmentUrl'
  },
  {
    cmp: 'radio',
    label: '发布范围',
    prop: 'issueLevel',
    options: [
      { key: 0, label: '全部商户' },
      { key: 2, label: '指定商户' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择发布范围', trigger: 'change' }]
    }
  },
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'merchantList',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择指定商户', trigger: 'change' }]
    },
    config: {
      placeholder: '请输入商户编号/商户名称',
      multiple: true,
      filterable: true,
      style: { width: '100%' }
    },
    show: 'form.issueLevel==2'
  },
  {
    cmp: 'radio',
    label: '发布状态',
    prop: 'issueMode',
    options: kvNoticeIssueMode,
    itemConfig: {
      rules: [{ required: true, message: '请选择发布状态', trigger: 'change' }]
    }
  },
  {
    cmp: 'dpicker',
    label: '发布时间',
    prop: 'issueTime',
    config: { type: 'datetime', 'value-format': 'yyyy-MM-dd HH:mm:ss' },
    itemConfig: {
      rules: [{ required: true, message: '请输入发布时间', trigger: 'change' }]
    },
    show: 'form.issueMode==2'
  },
  {
    cmp: 'radio',
    label: '是否开屏展示',
    prop: 'isShow',
    options: [
      { key: 1, label: '是' },
      { key: 0, label: '否' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择是否开屏展示', trigger: 'change' }]
    }
  }
]

export const formSign = [
  // {
  //   cmp: 'select',
  //   label: '签约税源地',
  //   prop: 'supplierId',
  //   config: { style: { width: '300px' } },
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择签约税源地', trigger: 'blur' }]
  //   }
  // },
  {
    cmp: 'select',
    label: '协议类型',
    prop: 'templateType',
    config: { style: { width: '300px' } },
    options: agreementType,
    itemConfig: {
      rules: [{ required: true, message: '请选择协议类型', trigger: 'change' }]
    }
  },
  {
    cmp: 'radio',
    label: '签约方式',
    prop: 'signType',
    options: [
      { key: 1, label: '线上签约' },
      { key: 2, label: '线下签约' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择签约方式', trigger: 'change' }]
    }
  },
  {
    cmp: 'radio',
    label: '签约模式',
    prop: 'signMode',
    options: [
      { key: 0, label: '自动签约' },
      { key: 1, label: '手动签约' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择签约模式', trigger: 'change' }]
    },
    show: 'form.signType==1'
  },
  {
    cmp: 'input',
    label: '协议名称',
    prop: 'contractName',
    config: { style: { width: '300px' } },
    itemConfig: {
      rules: [{ required: true, message: '请输入协议名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'slot',
    name: 'contractTemplateId',
    show: 'form.signType==1'
  },
  {
    cmp: 'slot',
    name: 'signatoryDtos',
    show: 'form.signType==1'
  },
  {
    cmp: 'slot',
    name: 'supplierId',
    show: 'form.signType!=1'
  },
  {
    cmp: 'input',
    label: '税源地签约主体',
    prop: 'mainName',
    disabled: true,
    config: { style: { width: '300px' } },
    itemConfig: {
      rules: [{ required: true, message: '请输入税源地主体', trigger: 'blur' }]
    },
    show: 'form.signType!=1'
  },
  { cmp: 'input', label: '备注', prop: 'signDesc', config: { style: { width: '100%' }, type: 'textarea', maxLength: 300 } }
]
export const formMainList = [
  {
    cmp: 'input',
    label: '社会统一信用代码',
    prop: 'creditCode',
    config: { style: { width: '300px' } },
    itemConfig: {
      rules: [{ required: true, message: '请输入社会统一信用代码', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '企业名称',
    prop: 'mainName',
    config: { style: { width: '300px' } },
    itemConfig: {
      rules: [{ required: true, message: '请输入企业名称', trigger: 'blur' }]
    }
  }
]
export const formPlan = [
  {
    cmp: 'dpicker',
    label: '结算所属期',
    prop: 'belongTime',
    config: { type: 'month', valueFormat: 'yyyy-MM', style: { width: '300px' } },
    itemConfig: {
      rules: [{ required: true, message: '请选择结算所属期', trigger: 'blur' }]
    }
  },
  {
    cmp: 'radio',
    label: '抽取商户范围',
    prop: 'isSpecifyScope',
    options: [
      { key: 0, label: '不指定' },
      { key: 1, label: '指定' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户范围', trigger: 'blur' }]
    }
  },
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'merchantIds',
    options: [],
    show: `form.isSpecifyScope==1`,
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      multiple: true,
      style: { width: '300px' }
    }
  }
]
//电话复核新增
export const formAddPhoneReview = [
  {
    cmp: 'dpicker',
    label: '结算所属期',
    prop: 'belongTime',
    config: { type: 'month', valueFormat: 'yyyy-MM', style: { width: '300px' } },
    itemConfig: {
      rules: [{ required: true, message: '请选择结算所属期', trigger: 'blur' }]
    }
  },

  {
    cmp: 'radio',
    label: '抽取商户范围',
    prop: 'isSpecifyScope',
    options: [
      { key: 0, label: '不指定' },
      { key: 1, label: '指定' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户范围', trigger: 'blur' }]
    }
  },
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'merchantIds',
    options: [],
    show: `form.isSpecifyScope==1`,
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      multiple: true,
      style: { width: '300px' }
    }
  }
]
//电话复合跟进
export const formFollowPhoneReview = [
  {
    cmp: 'dpicker',
    label: '跟进日期',
    prop: 'belongTime',
    config: { type: 'datetime', valueFormat: 'yyyy-MM-dd HH:mm:ss', style: { width: '300px' } },
    itemConfig: {
      rules: [{ required: true, message: '请选择跟进日期', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '跟进人',
    prop: 'creditCode',
    config: { style: { width: '300px' } },
    itemConfig: {
      rules: [{ required: true, message: '请输入跟进人', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '跟进记录',
    prop: 'creditCode',
    config: { style: { width: '300px' } },
    type: 'textarea',
    itemConfig: {
      rules: [{ required: true, message: '请输入跟进记录', trigger: 'blur' }]
    }
  },
  {
    cmp: 'select',
    label: '反馈结果',
    prop: 'QueryTimeType',
    itemConfig: {
      rules: [{ required: true, message: '请选择反馈结果', trigger: 'blur' }]
    },
    options: [
      { key: 1, label: '待确认' },
      { key: 2, label: '合格' },
      { key: 3, label: '不合格' }
    ]
  }
]
//发放抵扣金
export const formGive = [
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'merchantId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      filterable: true,
      style: { width: '200px' }
    },
    disabled: false
  },
  {
    cmp: 'txt',
    label: '发放类型',
    prop: 'type'
  },
  {
    cmp: 'number',
    label: '抵扣金',
    prop: 'diffServicefee',
    config: { style: { width: '200px' }, controls: false, precision: 2 },
    itemConfig: {
      rules: [{ required: true, message: '请输入数字，可精确到两位小数', trigger: 'blur' }]
    },
    afterSuffix: ' 元 抵扣金可抵服务费，无到期时间，永久有效'
  },
  {
    cmp: 'input',
    label: '发放说明',
    prop: 'applyRemark',
    config: { type: 'textarea' },

    itemConfig: {
      rules: [{ required: true, message: '请输入发放说明', trigger: 'blur' }]
    }
  }
]
//审核抵扣金
export const formAudit = [
  {
    cmp: 'txt',
    label: '选择商户',
    prop: 'merchantName'
  },
  {
    cmp: 'txt',
    label: '发起人',
    prop: 'applyer'
  },
  {
    cmp: 'txt',
    label: '发放类型',
    prop: 'type'
  },
  {
    cmp: 'mountFee',
    label: '抵扣金',
    prop: 'diffServicefee'
  },
  {
    cmp: 'input',
    label: '审核说明',
    prop: 'auditRemark',
    config: { type: 'textarea' },
    itemConfig: {
      rules: [{ required: true, message: '请输入发放说明', trigger: 'blur' }]
    }
  }
]
//更换充值帐户
export const formChangeAccount = [
  // {
  //   cmp: 'select',
  //   label: '发放通道',
  //   prop: 'channelId',
  //   options: [],
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择发放通道', trigger: 'blur' }]
  //   },
  //   config: {
  //     style: { width: '300px' }
  //   },
  //   disabled: true
  // },
  // {
  //   cmp: 'select',
  //   label: '开户方式',
  //   prop: 'openMode',
  //   options: kvOpenMode,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择开户方式', trigger: 'blur' }]
  //   },
  //   config: {
  //     filterable: true,
  //     style: { width: '300px' }
  //   }
  // },
  // {
  //   cmp: 'select',
  //   label: '充值帐户',
  //   prop: 'supplierAccountId',
  //   options: [],
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择充值帐户', trigger: 'blur' }]
  //   },
  //   config: {
  //     filterable: true,
  //     style: { width: '300px' }
  //   }
  // },
  // // 自动开户-银行卡
  // {
  //   cmp: 'select',
  //   label: '充值账户',
  //   prop: `supplierAccountId`,
  //   options: [],
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择充值账户', trigger: 'change' }]
  //   },
  //   config: {
  //     style: 'width: 350px'
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==1`
  // },
  // // 手动开户-银行卡
  // {
  //   cmp: 'input',
  //   label: '账户名称',
  //   prop: `accountName`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入账户名称', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==3`
  // },
  // {
  //   cmp: 'input',
  //   label: '开户银行',
  //   prop: `accountBank`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入开户银行', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==3`
  // },
  // {
  //   cmp: 'input',
  //   label: '银行账户',
  //   prop: `accountNumber`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入银行账户', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==3`
  // },
  // {
  //   cmp: 'input',
  //   label: '银行行号',
  //   prop: `accountBankCode`,
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==3`
  // },
  // // 手动开户-支付宝
  // {
  //   cmp: 'input',
  //   label: '付款账号',
  //   prop: `alipayAccountNumber`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入付款账号', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4`
  // },
  // {
  //   cmp: 'input',
  //   label: '账户名称',
  //   prop: `alipayAccountName`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入账户名称', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4`
  // },
  // {
  //   cmp: 'radio',
  //   label: '汇款充值信息',
  //   prop: `isRemitAccount`,
  //   options: [
  //     { key: false, label: '关闭' },
  //     { key: true, label: '开启' }
  //   ],
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择是否开启汇款充值信息', trigger: 'change' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4`
  // },
  // {
  //   cmp: 'input',
  //   label: '户名',
  //   prop: `accountName`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入银行账户', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4&&form.isRemitAccount`
  // },
  // {
  //   cmp: 'input',
  //   label: '账号',
  //   prop: `accountNumber`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入银行账号', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4&&form.isRemitAccount`
  // },
  // {
  //   cmp: 'input',
  //   label: '银行',
  //   prop: `accountBank`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入开户行名称', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4&&form.isRemitAccount`
  // },
  // {
  //   cmp: 'input',
  //   label: '支行',
  //   prop: `accountBranch`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入开户行支行名称', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4&&form.isRemitAccount`
  // },
  // {
  //   cmp: 'input',
  //   label: '开户地',
  //   prop: `accountAddresses`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入开户地', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4&&form.isRemitAccount`
  // },
  // {
  //   cmp: 'input',
  //   label: '联行号',
  //   prop: `accountLines`,
  //   itemConfig: {
  //     rules: [{ required: true, message: '请输入联行号', trigger: 'blur' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode==4&&form.isRemitAccount`
  // },
  // {
  //   cmp: 'radio',
  //   label: '充值记录',
  //   prop: `generationMode`,
  //   options: [
  //     { key: 1, label: '自动生成' },
  //     { key: 2, label: '手动提交' }
  //   ],
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择', trigger: 'change' }]
  //   },
  //   show: `!!form.channelId&&form.channelId!="000"&&form._supplierType${stype}&&form.openMode`
  // },
  // {
  //   cmp: 'radio',
  //   label: '充值记录',
  //   prop: 'generationMode',
  //   options: [
  //     { key: 1, label: '自动生成' },
  //     { key: 2, label: '手动提交' }
  //   ],
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择充值记录', trigger: 'blur' }]
  //   }
  // }
]
//短信模板
export const formSMS = [
  {
    cmp: 'select',
    label: '短信模板分类',
    prop: 'smsCategory',
    itemConfig: {
      rules: [{ required: true, message: '请选择短信模板分类', trigger: 'blur' }]
    },
    options: [
      { key: '短信验证码', label: '短信验证码' },
      { key: '商户登录短信', label: '商户登录短信' },
      { key: '创客短信签约', label: '创客短信签约' },
      { key: '风控通知短信', label: '风控通知短信' },
      { key: '退回短信通知', label: '退汇短信通知' }
    ]
  },
  {
    cmp: 'input',
    label: '短信模板名称',
    prop: 'smsName',
    config: { placeholder: '请填写模板名称，如风控通知短信' },
    itemConfig: {
      rules: [{ required: true, message: '请输入短信模板名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'checkbox',
    label: '收件人',
    prop: 'smsRecipients',
    itemConfig: {
      rules: [{ required: true, message: '收件人', trigger: 'blur' }]
    },
    options: [
      { key: '创客', label: '创客' },
      { key: '商户', label: '商户' },
      { key: '税源地', label: '税源地' },
      { key: '运营', label: '运营' }
    ]
  },

  {
    cmp: 'radio',
    label: '短信签名',
    prop: `smsSignature`,
    options: [{ key: '【灵时云】', label: '【灵时云】' }],
    itemConfig: {
      rules: [{ required: true, message: '请选择短信签名', trigger: 'change' }]
    }
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'smsRemark',
    config: { type: 'textarea', maxlength: 200, placeholder: '请输入备注，可记录短信用途或触发条件等' },
    itemConfig: {
      // rules: [{ required: true, message: '请输入备注', trigger: 'blur' }]
    }
  }
]
