<template>
  <el-dialog title="审核" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <!-- 内容区域 -->
    <div :style="{ height: detail.payVoucher ? '500px' : needSelMerchant ? '200px' : '100px' }" class="dialog-wrap">
      <el-descriptions title="" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="申请时间">{{ detail.rechargeTime || '--' }}</el-descriptions-item>
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}【{{ detail.memberCode || '无' }}】</el-descriptions-item>
        <el-descriptions-item label="充值金额"
          ><span v-format="'#,##0.00'">{{ detail.rechargeAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="收款账号">{{ detail.rechargeAccountNumber || '无' }}</el-descriptions-item>
      </el-descriptions>
      <div class="img-box">
        <img class="img" :src="detail.payVoucher" alt="" />
      </div>
      <div v-if="needSelMerchant">
        <el-divider></el-divider>
        <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '120px' }"> </FormView>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="check(2)">审核不通过</el-button>
      <el-button type="primary" @click="check(1)">审核通过</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      fromLabels: [
        {
          cmp: 'select',
          label: '选择商户',
          prop: 'merchantAccountId',
          options: [],
          itemConfig: {
            rules: [{ required: true, message: '请选择商户', trigger: 'change' }]
          },
          config: {
            // placeholder: '请输入商户编号/商户名称',
            // filterable: true,
            style: { width: '90%' }
          }
        }
      ]
    }
  },
  computed: {
    needSelMerchant() {
      return this.detail.id && (!this.detail.merchantName || !this.detail.memberCode)
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.show = true
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetRechargeLogInfo({ rechargeId: this.id })
      this.detail = res
      if (!res.merchantName || !memberCode) this.getMerchantList()
    },
    // 获取主体商户列表
    async getMerchantList() {
      const res = await this.$api.GetRechargeMerchantAccountList({ Id: this.id })
      // 处理数据结构并赋值
      this.fromLabels.find((item) => item.prop == 'merchantAccountId').options = (res.list || []).map((item) => {
        return Object.assign(
          {
            label: item.merchantAccountRemark,
            key: item.merchantAccountId
          },
          item
        )
      })
    },
    async check(state) {
      let params = {
        financeRechargeId: this.id
      }
      if (state == 2) {
        const { value } = await this.$prompt('请输入审核不通过理由', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea',
          inputPlaceholder: '请输入审核不通过的理由,不超过200字',
          inputPattern: /^\S+$/,
          inputErrorMessage: '请输入审核不通过理由'
        })
        params.failCause = value
      }
      let res
      if (state == 2) {
        res = await this.$api.AuditNotPassRechargeLog(params)
        this.$message.success('已审核不通过')
      } else {
        // 判断是否需要选择商户merchantAccountId
        if (this.needSelMerchant) {
          const otherParams = await this.$refs.vForm.getForm()
          params = { ...params, ...otherParams }
        }
        res = await this.$api.AuditPassRechargeLog(params)
        this.$message.success('已审核通过')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
<style lang="scss" scoped>
.img-box {
  text-align: center;
  width: 100%;
  .img {
    margin-right: 18px;
    width: 98%;
    height: 100%;
  }
}
</style>
