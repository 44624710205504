<template>
  <el-dialog
    :title="(!id ? '添加' : '编辑') + title"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    top="8vh"
    width="1200px"
  >
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 600px" v-if="show">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }" @onInputChange="onInputChange">
        <template v-slot:openInfo="{ form, idx }">
          <el-col>
            <div style="display: flex; justify-content: flex-end; margin-right: 100px; margin-bottom: 18px">
              <el-button @click="addAccount">添加</el-button>
            </div>
            <div style="margin-left: 200px; margin-right: 100px">
              <el-table :data="form.supplierAccounts" :cell-style="tdStyle">
                <el-table-column label="开户行" prop="accountBank" width="210px">
                  <template slot-scope="{ row, $index }">
                    <el-form-item
                      label-width="0"
                      :prop="'supplierAccounts.' + $index + '.accountBank'"
                      :rules="[
                        {
                          required: true,
                          message: '请填写开户行',
                          trigger: 'blur'
                        }
                      ]"
                    >
                      <el-input v-model="row.accountBank" placeholder="请填写开户行"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="开户名称" prop="accountName" width="210px">
                  <template slot-scope="{ row, $index }">
                    <el-form-item
                      label-width="0"
                      :prop="'supplierAccounts.' + $index + '.accountName'"
                      :rules="[
                        {
                          required: true,
                          message: '请填写开户名称',
                          trigger: 'blur'
                        }
                      ]"
                    >
                      <el-input v-model="row.accountName" placeholder="请填写开户名称"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="银行配置" prop="accountConfig" width="340px">
                  <template slot-scope="{ row, $index }">
                    <el-form-item
                      label-width="0"
                      :prop="'supplierAccounts.' + $index + '.accountConfig'"
                      :rules="[
                        {
                          required: true,
                          message: '请填写银行配置',
                          trigger: 'blur'
                        }
                      ]"
                    >
                      <el-input v-model="row.accountConfig" placeholder="请填写银行配置"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button v-if="!scope.row.id" type="text" style="color: red" @click="deleteItem(scope.$index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </template>
        <template v-slot:taxTable="{ form, idx }">
          <el-col>
            <el-form-item label="可开票税目" required>
              <el-popover placement="bottom-start" width="600" trigger="manual" v-model="showTaxPopover">
                <div slot="reference">
                  <el-button type="primary" @click="openTaxPopover">添加</el-button>
                  <el-button v-if="id" style="float: right; margin-right: 100px" type="primary" icon="el-icon-download" :loading="btnloadDownload" @click="exportTax">导出</el-button>
                </div>
                <div style="max-height: 500px; overflow: auto" class="tax-pop">
                  <div class="text">税目列表</div>
                  <el-tree
                    :data="taxItemsLevelList"
                    show-checkbox
                    default-expand-all
                    node-key="value"
                    :default-checked-keys="checkTaxItemsId"
                    ref="taxAllTree"
                  >
                    <span class="custom-tree-node" slot-scope="{ data }">
                      <span class="label">{{ data.title || '' }}</span
                      >&nbsp;
                      <span class="label-num">{{ data.taxCode || '' }}</span>
                    </span>
                  </el-tree>
                </div>
                <div style="text-align: right; margin: 0">
                  <el-button type="text" @click="showTaxPopover = false">取消</el-button>
                  <el-button type="primary" @click="addTax">确定</el-button>
                </div>
              </el-popover>
              <div style="margin-top: 18px; margin-right: 100px">
                <el-table :data="taxTable">
                  <el-table-column label="税目">
                    <template slot-scope="{ row, $index }">
                      {{ row.baseTaxItemsName + ' * ' + row.taxItemsName }}
                    </template>
                  </el-table-column>
                  <el-table-column label="税目状态" width="120px">
                    <template slot-scope="{ row, $index }">
                      {{ row.isEnable ? '已停用' : '生效' }}
                    </template>
                  </el-table-column>
                  <el-table-column label="调整时间" width="160px">
                    <template slot-scope="{ row, $index }">
                      {{ row.updateTime || row.createTime || '' }}
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="120px">
                    <template slot-scope="{ row, $index }">
                      <template v-if="row.id">
                        <el-button v-if="row.isEnable" type="text" @click="onTax(row)">启用</el-button>
                        <el-button v-else type="text" style="color: red" @click="offTax(row)">停用</el-button>
                      </template>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formCreatPark } from '@/configs/forms'
import { downloadExcel } from '@/utils'
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      title: '税源地',
      fromLabels: formCreatPark,
      detail: {},
      supplierAccounts: [],
      showTaxPopover: false,
      // 税目
      taxItemsLevelList: [],
      checkTaxItemsId: [],
      // 显示税目表格
      taxTable: [],
      btnloadDownload: false
    }
  },
  computed: {
    isShowAccountTitle(form, idx) {
      return (form, idx) => {
        if (idx == 10 && form.supplierType == 2) {
          return true
        }
      }
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.supplierAccounts = []
      // 税目信息
      this.showTaxPopover = false
      this.taxItemsLevelList = []
      this.checkTaxItemsId = []
      this.taxTable = []
      this.btnloadDownload = false
    },
    async open(obj) {
      this.clear()
      this.id = null
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
        //
      } else {
        // 新建
      }
      await this.getTaxItemsLevelList()
      this.show = true
      this.$nextTick(() => {
        let params = {
          supplierType: 1,
          supplierAccounts: this.supplierAccounts,
          isPeopleSign: 0,
          isSync: 0,
          confirmReceiptsMode: 0
        }
        if (obj) {
          this.$refs.vForm.fillData(Object.assign({ ...params }, this.detail))
        } else {
          this.$refs.vForm.fillData(params)
        }
      })
    },
    // 新增一条开户主体
    addAccount() {
      this.$refs.vForm.form.supplierAccounts.push({
        accountBank: '',
        accountName: '',
        accountConfig: ''
      })
    },
    // 删除一条开户主体信息
    deleteItem(idx) {
      this.$refs.vForm.form.supplierAccounts.splice(idx, 1)
    },
    // 添加模板效验后出现样式变化,这里进行调整
    tdStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2) {
        return 'text-align:center;padding-bottom:0px;padding-top:14px'
      }
    },
    async getDetail() {
      const res = await this.$api.GetEditBySupplierInfo({
        supplierId: this.id
      })
      this.detail = Object.assign(res, {
        isEdit: true,
        isAuthentication: parseInt(res.isAuthentication),
        organizationType: parseInt(res.organizationType),
        isEntrust: parseInt(res.isEntrust),
        syncInvoiceType: res.invoiceTypeList
      })
      this.supplierAccounts = [].concat(res.supplierAccounts)

      // 模拟dev数据
      // this.detail.supplierTaxItemList = [{
      //   id:1,
      //       baseTaxItemsId: "032d34f99f1c468399c81ec24c42cddc",
      //       baseTaxItemsName: "刘晓轩",
      //       taxItemsId: "0e3b387e9a21402081b9e5012cae4d7b",
      //       taxItemsName: "03",
      //       isEnable: 0
      //     },{
      //   id:2,
      //       baseTaxItemsId: "032d34f99f1c468399c81ec24c42cddc",
      //       baseTaxItemsName: "刘晓轩",
      //       taxItemsId: "10804382e40143d1a49199eb30732aae",
      //       taxItemsName: "0999",
      //       isEnable: 0
      //     },{
      //   id:3,
      //       baseTaxItemsId: "50cd58dbb2184a36995a099ac30f3604",
      //       baseTaxItemsName: "信息技术服务",
      //       taxItemsId: "8e09edbac3bf4785baa30d4c9eafb47c",
      //       taxItemsName: "互联网信息服务",
      //       isEnable: 0
      //     }]
      // 税目表格
      this.taxTable = (this.detail.supplierTaxItemList || []).sort((a, b) => a.sort - b.sort)
    },
    onInputChange(val, key) {
      if (!this.id && key == 'creditCode') {
        this.creditCodeChange(val)
      } else if (!this.id && key == 'expeCreditCode') {
        this.expeCreditCodeChange(val)
      }
    },
    async creditCodeChange(val) {
      const res = await this.$api.GetSupplierByBusinessMain({
        creditCode: val
      })
      const initObj = {
        isAuthentication: 0,
        authorizationType: 0,
        businessLicense: null,
        isMainDisabled: 2,
        mainName: ''
      }
      // 空处理
      if (!res.id) return this.$refs.vForm.upData(initObj)
      const { mainName, businessLicense, creditCode, isAuthentication, authorizationType } = res
      this.$refs.vForm.upData({
        mainName,
        businessLicense,
        creditCode,
        isAuthentication,
        authorizationType,
        isMainDisabled: 1
      })
    },
    // 获取开户主体认证信息
    async expeCreditCodeChange(val) {
      const res = await this.$api.GetBusinessMain({ creditCode: val })
      const initObj = {
        isAuthentication: 0,
        authorizationType: 0,
        businessLicense: null,
        secondaryDisbled: 2,
        expeMainName: ''
      }
      // 空处理
      if (!res.id) return this.$refs.vForm.upData(initObj)
      if (res && res.mainName) {
        const { mainName: expeMainName, isAuthentication: expeIsAuthentication, businessLicense: expeBusinessLicense } = res
        this.$refs.vForm.upData(
          Object.assign({
            expeMainName,
            expeIsAuthentication,
            expeBusinessLicense,
            secondaryDisbled: 1
          })
        )
      }
    },
    // 可开票税目相关
    // 获取全部税目列表
    async getTaxItemsLevelList() {
      const res = await this.$api.GetTaxItemsLevelList()
      const taxItemsLevelList = res.list.map((item) => {
        if (item.children && item.children.length)
          item.children = item.children.map((it) => {
            it.parent_value = item.value
            // 禁用子节点
            if ((this.detail.supplierTaxItemList || []).find((sit) => sit.taxItemsId == it.value)) {
              it.disabled = true
            }
            return it
          })
        else {
          // 禁用单及父节点
          if ((this.detail.supplierTaxItemList || []).find((sit) => sit.taxItemsId == item.value)) {
            item.disabled = true
          }
        }
        return item
      })
      // 遍历父节点，查找子节点全禁用情况
      if ((this.detail.supplierTaxItemList || []).length) {
        this.taxItemsLevelList = taxItemsLevelList.map((item) => {
          if (item.children && item.children.length) {
            const no = item.children.find((it) => !it.disabled)
            if (!no) item.disabled = true
          }
          return item
        })
      } else {
        this.taxItemsLevelList = taxItemsLevelList
      }
    },
    openTaxPopover() {
      this.$refs.taxAllTree.setCheckedKeys([])
      this.checkTaxItemsId = this.taxTable.filter((it) => !it.isEnable).map((it) => it.taxItemsId)
      this.showTaxPopover = true
    },
    addTax() {
      const allList = this.$refs.taxAllTree.getCheckedNodes(false, true)
      const rlist = []
      // 遍历缓存父级信息，提取子级信息
      let fone = {}
      for (let fi = 0; fi < allList.length; fi++) {
        const one = allList[fi]
        if (one.children) {
          if (!one.children.length) {
            rlist.push({
              baseTaxItemsId: one.value,
              baseTaxItemsName: one.title,
              taxItemsId: one.value,
              taxItemsName: one.title
            })
          } else {
            fone = one
          }
        } else {
          if (!one.disabled)
            rlist.push({
              baseTaxItemsId: fone.value,
              baseTaxItemsName: fone.title,
              taxItemsId: one.value,
              taxItemsName: one.title
            })
        }
      }
      this.taxTable = [...(this.detail.supplierTaxItemList || []).sort((a, b) => a.sort - b.sort), ...rlist]
      this.showTaxPopover = false
    },
    offTax(item) {
      item.isEnable = 1
    },
    onTax(item) {
      item.isEnable = 0
    },
    async exportTax() {
      await this.$api.ExportSupplierTax({supplierId: this.id})
      this.btnloadDownload = true
      const res = await this.$api.ExportSupplierTax({supplierId: this.id}).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, `税源地可开票税目`)
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true

      // 处理税目
      if (!this.taxTable.length) {
        this.$message.error('请添加可开票税目')
        this.btnloadSubmit = false
        return false
      }
      const supplietTaxList = this.taxTable.map((it, idx) => {
        return {
          taxId: it.taxItemsId,
          isEnable: it.isEnable || 0,
          sort: idx
        }
      })
      if (this.id) {
        // 编辑
        const {
          linkPerson,
          linkTel,
          supplierName,
          remark,
          mainName,
          businessLicense,
          creditCode,
          supplierType,
          id: supplierId,
          expeCreditCode,
          expeMainName,
          expeBusinessLicense,
          supplierAccounts,
          syncInvoiceType,
          isPeopleSign,
          isSync,
          syncConfig,
          businessType,
          confirmReceiptsTemplateurl,
          confirmReceiptsMode
        } = params
        const res = await this.$api
          .EditSupplierInfo({
            supplierId: this.id,
            linkPerson,
            linkTel,
            supplierName,
            remark,
            mainName,
            businessLicense,
            creditCode,
            supplierType,
            supplierId,
            expeBusinessLicense,
            expeMainName,
            expeCreditCode,
            supplierAccounts,
            syncInvoiceType,
            isPeopleSign,
            isSync,
            syncConfig,
            businessType,
            confirmReceiptsTemplateurl,
            confirmReceiptsMode,
            supplietTaxList
          })
          .finally(() => {
            this.btnloadSubmit = false
          })
        this.$message.success('编辑成功')
      } else {
        // 添加
        // return
        const res = await this.$api.AddSupplierInfo({ ...params, supplietTaxList }).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
