import {
  formatStatus,
  formatStatusHasColor,
  kvTableTemplateStatus,
  kvRechargeStatus,
  kvRechargeType,
  kvPayState,
  kvTableWorkPeopleContract,
  kvWorkPlanPayState,
  kvWorkPlanAuditState,
  kvWorkPlanGrantState,
  kvAuthState,
  kvGrantType,
  kvContractMode,
  kvASignState,
  charmStatus,
  kvTemplateType,
  kvSignType,
  timestampToTime,
  taxState,
  kvRoleEenable,
  signType,
  parkBM,
  kvBM,
  authorization,
  kvNoticeType,
  kvNoticeIssueMode,
  kvNoticeIssueState,
  effectiveState,
  archivedStatus,
  kvWithdrawState,
  whiteAuditState,
  kvState,
  kvBillState,
  kvBusinessUploadState,
  kvBusinessAuditState,
  kvBusinessWayState,
  kvBusinessState,
  kvAuditState
} from './formatters'
import Vue from 'vue'
const vm = new Vue()

// 表格模版
export const tableTemplate = [
  { label: '标准文本', prop: 'xxa' },
  { label: '长文本缩略', prop: 'xxb', config: { showOverflowTooltip: true } },
  {
    label: 'html',
    prop: 'xxc',
    cmp: 'html'
  },
  {
    label: '状态',
    prop: 'xxd',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvTableTemplateStatus, cellValue)
    }
  },
  {
    label: '带颜色状态',
    prop: 'xxe',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvTableTemplateStatus, cellValue)
    }
  },
  { label: '可切换状态', prop: 'xxf', cmp: 'switch' },
  { label: '时间', prop: 'xxg' },
  {
    label: 'format',
    prop: 'xxh',
    formatter: (row, column, cellValue, index) => {
      return cellValue + row.xxi
    }
  },
  { label: '图片', prop: 'xxi', cmp: 'img' },
  { label: '长文本', prop: 'xxj', config: { width: '1200' } }
]

//  创客列表
export const tableWorkPeople = [
  { label: '人员编号', prop: 'peopleNo' },
  { label: '创客姓名', prop: 'name' },
  { label: '身份证号', prop: 'idCard' },
  { label: '手机号', prop: 'mobile' },
  { label: '地址', prop: 'address' },
  { label: '签证机关', prop: 'issueAuthority' }
]

//  结算明细审核
export const tableWorkPlanCheck = [
  { label: '结算单号', prop: 'planNo', config: { width: 140 } },
  {
    label: '所属任务',
    prop: 'taskName',
    config: { showOverflowTooltip: true }
  },
  { label: '商户名称', prop: 'merchantName', config: { width: 150 } },
  { label: '商户号', prop: 'memberCode' },
  { label: '认证企业', prop: 'mainName', config: { width: 150 } },
  { label: '付款通道', prop: 'paymentChannel' },
  { label: '提交金额', prop: 'issueAmount', cmp: 'money' },
  { label: '人数', prop: 'totalPeopleNum' },
  // { label: '结算金额', prop: 'settleAmount', cmp: 'money' },
  {
    label: '税源地-通道',
    prop: 'supplierName',
    config: { width: 150 },
    formatter: (row, column, cellValue, index) => {
      return (cellValue || '--') + '-' + (row.channelName || '--')
    }
  },
  {
    label: '审核状态',
    prop: 'auditState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvWorkPlanAuditState, cellValue)
    }
  },
  {
    label: '发放状态',
    prop: 'grantState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPlanGrantState, cellValue)
    }
  },
  {
    label: '发放进度',
    prop: 'grantFinishCount',
    formatter: (row, column, cellValue, index) => {
      return (cellValue || 0) + '/' + (row.grantRejectCount || 0) + '/' + (row.pendingCount || 0) + '/' + (row.totalPeopleNum || 0)
    },
    config: {
      headInfo: '发放完成/拒绝发放/挂起/总人数'
    }
  },
  { label: '成功金额', prop: 'successAmount', cmp: 'money' },
  {
    label: '申请时间',
    prop: 'auditStartTime',
    formatter: (row, column, cellValue, index) => {
      return cellValue || '--'
    },
    config: { width: 140 }
  },
  {
    label: '备注',
    prop: 'remark',
    config: {
      'show-overflow-tooltip': true
    }
  }
]

// 结算审核里面的明细
export const tableWorkPlanWorkDetail = [
  { label: '订单编号', prop: 'customerOrderNo' },
  { label: '姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard' },
  { label: '手机号', prop: 'mobile' },
  {
    label: '签约状态',
    prop: 'isSign',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvTableWorkPeopleContract, cellValue, '未签约')
    }
  },
  { label: '下发类型', prop: 'grantType' },
  { label: '账号名', prop: 'accountName' },
  { label: '账号', prop: 'accountNumber' },
  { label: '税后金额', prop: 'afterTaxAmount', cmp: 'money' },
  { label: '服务费', prop: 'serviceFee', cmp: 'money' },
  { label: '差额服务费', prop: 'serviceDiffFee', cmp: 'money' },
  { label: '服务费抵扣金', prop: 'deductionAmount', cmp: 'money' },
  { label: '支付金额', prop: 'balanceAmount', cmp: 'money' },
  { label: '订单金额', prop: 'orderAmount', cmp: 'money' },
  {
    label: '验证状态',
    prop: 'verifyCheck',
    cmp: 'tooltipList',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未验证' },
          { key: 1, label: '已验证' }
        ],
        cellValue
      )
    },
    toolTipFee: [
      {
        label: '验证结果',
        prop: 'verifyResult',
        formatter: (row, column, value, index) => {
          return formatStatus(
            [
              { key: 0, label: '不通过' },
              { key: 1, label: '通过' }
            ],
            value
          )
        }
      },
      { label: '原因', prop: 'verifyDesc' }
    ]
  },
  {
    label: '打款状态',
    prop: 'payState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPlanPayState, cellValue)
    }
  },
  {
    label: '备注',
    prop: 'remark',
    config: {
      'show-overflow-tooltip': true
    }
  }
]

//  结算明细列表
export const tableWorkPlanDetail = [
  { label: '订单编号', prop: 'customerOrderNo', config: { width: 170 } },
  { label: '结算单编号', prop: 'planNo' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户号', prop: 'memberCode' },
  // { label: '认证企业', prop: 'mainName' },
  // { label: '姓名', prop: 'userName' },
  // { label: '证件号', prop: 'idCard', config: { width: 150 } },
  // { label: '手机号', prop: 'mobile' },
  { label: '下发类型', prop: 'grantType' },
  { label: '税后金额', prop: 'afterTaxAmount', cmp: 'money' },
  { label: '订单金额', prop: 'orderAmount', cmp: 'money' },
  { label: '利润', prop: 'profitAmount', cmp: 'money' },
  { label: '税源地', prop: 'supplierName' },
  { label: '开票税目', prop: 'taxName', config: { width: 150 } },
  { label: '创建时间', prop: 'createTime', config: { width: 150 } },
  { label: '打款时间', prop: 'completionTime', config: { width: 150 } },
  {
    label: '验证状态',
    prop: 'verifyCheck',
    cmp: 'tooltipList',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未验证' },
          { key: 1, label: '已验证' }
        ],
        cellValue
      )
    },
    toolTipFee: [
      {
        label: '验证结果',
        prop: 'verifyResult',
        formatter: (row, column, value, index) => {
          return formatStatus(
            [
              { key: 0, label: '不通过' },
              { key: 1, label: '通过' }
            ],
            value
          )
        }
      },
      { label: '原因', prop: 'verifyDesc' }
    ]
  },
  {
    label: '打款状态',
    prop: 'payState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvPayState, cellValue)
    }
  },
  {
    label: '开票状态',
    prop: 'billState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvBillState, cellValue)
    }
  },
  { label: '失败原因', prop: 'failCause' }
]

//  充值记录
export const tableRechargeLog = [
  { label: '打款时间', prop: 'rechargeTime' },
  { label: '流水号', prop: 'rechargeNo' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户编号', prop: 'memberCode' },
  {
    label: '类型',
    prop: 'rechargeType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvRechargeType, cellValue)
    },
    config: { width: 100 }
  },
  { label: '充值金额', prop: 'rechargeAmount', cmp: 'money' },
  { label: '收款账户名称', prop: 'rechargeAccountName' },
  { label: '收款账户号码', prop: 'rechargeAccountNumber' },
  { label: '收款开户银行', prop: 'rechargeAccountBank' },
  // { cmp: 'money', label: '充值后余额', prop: 'balance' },
  {
    label: '审核状态',
    prop: 'auditState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核通过' },
          { key: 2, label: '审核不通过' }
        ],
        cellValue
      )
    },
    config: { width: 100 }
  },
  {
    label: '充值状态',
    prop: 'rechargeState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvRechargeStatus, cellValue)
    },
    config: { width: 100 }
  },
  { label: '失败原因', prop: 'failCause' }
]

//  支出记录
export const tableDisburseLog = [
  { label: '打款时间', prop: 'disburseTime' },
  {
    label: '打款状态',
    prop: 'grantState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 1, label: '打款成功' },
          { key: 2, label: '打款失败' }
        ],
        cellValue
      )
    }
  },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户号', prop: 'memberCode' },
  { label: '企业主体', prop: 'mainName' },
  {
    label: '支付方式',
    prop: 'grantType'
    // formatter: (row, column, cellValue, index) => {
    //   return formatStatus(
    //     [
    //       { key: 1, label: '银行卡' },
    //       { key: 2, label: '支付宝' },
    //       { key: 3, label: '微信' }
    //     ],
    //     cellValue
    //   )
    // }
  },
  { label: '金额', prop: 'afterTaxAmount', cmp: 'money' },
  { label: '收款人', prop: 'accountName' },
  { label: '收款账户', prop: 'accountNumber' },
  { label: '所属任务', prop: 'taskName' },
  { label: '任务编号', prop: 'taskNo' },
  { label: '订单号', prop: 'disburseNo' }
]

//  提现记录
export const tableWithDrawal = [
  { label: '提现单号', prop: 'withdrawNo' },
  { label: '商户编号', prop: 'memberCode' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '提现账户', prop: 'payAccountNumber' },
  { label: '提现开户行', prop: 'payAccountBank' },
  { label: '提现金额', prop: 'withdrawAmount', cmp: 'money' },
  { label: '收款账号', prop: 'withdrawAccountNumber' },
  { label: '开户行', prop: 'withdrawAccountBank' },
  { label: '备注', prop: 'remark' },
  { label: '申请时间', prop: 'withdrawTime' },
  { label: '审核时间', prop: 'auditTime' },
  {
    label: '状态',
    prop: 'withdrawState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWithdrawState, cellValue)
    },
    config: { width: 100 }
  },
  { label: '失败原因', prop: 'withdrawDesc' }
]

// 系统-用户管理
export const tableOperationUser = [
  { label: '用户编号', prop: 'userCode' },
  { label: '用户名称', prop: 'userName' },
  { label: '登录账号', prop: 'loginName' },
  { label: '联系方式', prop: 'mobile' },
  { label: '角色', prop: 'roleNmae' },
  {
    label: '禁用/启用',
    prop: 'isEnable',
    cmp: 'switch',
    switchConfig: { activeValue: '0', inactiveValue: '1' }
  }
]

// 开票审核中的退回列表
export const tableInvoiceReturn = [
  { label: '订单号', prop: 'customerOrderNo', config: { width: 200 } },
  { label: '结算单编号', prop: 'planNo' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户号', prop: 'memberCode' },
  { label: '下发类型', prop: 'grantType' },
  { label: '税后金额', prop: 'afterTaxAmount', cmp: 'money' },
  { label: '服务费', prop: 'serviceFee', cmp: 'money' },
  { label: '差额服务费', prop: 'serviceDiffFee', cmp: 'money' },
  { label: '抵扣金', prop: 'deductionAmount', cmp: 'money' },
  { label: '订单金额', prop: 'orderAmount', cmp: 'money' },
  {
    label: '打款状态',
    prop: 'payState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvPayState, cellValue)
    }
  },
  { label: '添加时间', prop: 'createTime', config: { width: 150 } }
]

// 开票审核
export const tableInvoice = [
  { label: '申请编号', prop: 'applyNumber', config: { width: 200 } },
  { label: '申请时间', prop: 'createTime', config: { width: 150 } },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户号', prop: 'memberCode' },
  { label: '认证企业', prop: 'mainName' },
  { label: '申请金额', prop: 'applyAmount', cmp: 'money' },
  {
    label: '合并笔数',
    prop: 'planCount',
    formatter: (row, column, cellValue, index) => {
      if (row.invoicingSource != '2') return row.planCount
      return ''
    }
  },
  // { label: '结算明细数', prop: 'planCount' },
  // { label: '平账结算单', prop: 'planDSOCount' },
  { label: '发票类型', prop: 'invoiceType' },
  { label: '税源地', prop: 'supplierName', config: { width: 150 } },
  { label: '开票税目', prop: 'invoiceItem', config: { width: 150 } },
  {
    label: '开票金额',
    prop: 'invoiceAmount',
    formatter: (row, column, cellValue, index) => {
      if (row.verifyState == '审核已通过') return vm.textFormat(cellValue, '#,##0.00')
      return ''
    }
  },
  { label: '备注', prop: 'remark', config: { showOverflowTooltip: true } },
  { label: '审核状态', prop: 'verifyState' }
]

// 发票的plan明细
export const tableBillPlan = [
  { label: '订单编号', prop: 'customerOrderNo' },
  { label: '结算单号', prop: 'planNo' },
  { label: '支付金额', prop: 'settleAmount', cmp: 'money' },
  { label: '任务编号', prop: 'taskNo' },
  { label: '任务名称', prop: 'taskName' }
]

// 发票的receipt明细
export const tableBillReceipt = [
  { label: '发票编号', prop: 'receiptNo' },
  { label: '发票金额', prop: 'receiptAmount', cmp: 'money' }
]

// 开票记录
export const tableBillInfo = [
  { label: '申请编号', prop: 'applyNumber', config: { width: 200 } },
  { label: '申请时间', prop: 'createTime', config: { width: 150 } },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户号', prop: 'memberCode' },
  { label: '认证企业', prop: 'mainName' },
  { label: '申请金额', prop: 'applyAmount', cmp: 'money' },
  {
    label: '合并笔数',
    prop: 'planCount',
    formatter: (row, column, cellValue, index) => {
      if (row.invoicingSource != '2') return row.planCount
      return ''
    }
  },
  // { label: '结算明细数', prop: 'planCount' },
  // { label: '平账结算单', prop: 'planDSOCount' },
  { label: '发票类型', prop: 'invoiceType', config: { width: 150 } },
  { label: '税源地', prop: 'supplierName', config: { width: 150 } },
  { label: '开票税目', prop: 'invoiceItem', config: { width: 150 } },
  { label: '开票金额', prop: 'invoiceAmount', cmp: 'money' },
  { label: '备注', prop: 'remark', config: { showOverflowTooltip: true } },
  { label: '开票状态', prop: 'billState' },
  { label: '配送状态', prop: 'distributionState' },
  {
    label: '失败原因',
    prop: 'hmApplyRemark',
    config: { showOverflowTooltip: true }
  }
]

// 完税证明
export const tableTaxVoucher = [
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户号', prop: 'memberCode' },
  { label: '认证企业', prop: 'merchantName' },
  { label: '完税月份', prop: 'dateMonth' },
  { label: '创建时间', prop: 'createTime' }
]

// 任务
export const tableTask = [
  { label: '商户编号', prop: 'memberCode' },
  {
    label: '商户名称',
    prop: 'merchantName',
    config: { showOverflowTooltip: true }
  },
  { label: '认证企业', prop: 'mainName' },
  { label: '创建时间', prop: 'taskCreateTime' },
  { label: '任务编号', prop: 'taskNumber' },
  { label: '任务名称', prop: 'taskName' },

  {
    label: '任务类型',
    prop: 'taskType',
    config: { showOverflowTooltip: true },
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 0) return '结算任务'
      if (cellValue == 1) return '普通任务'
    }
  },
  {
    label: '审核状态',
    prop: 'auditStatus',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核通过' },
          { key: 2, label: '审核未通过' }
        ],
        cellValue
      )
    }
  },
  {
    label: '任务状态',
    prop: 'taskState'
  },
  { label: '结算单数', prop: 'workPlanNum' },
  { label: '累计发放金额', prop: 'totalPaymentAmount', cmp: 'money' }
]

// 创客合同列表
export const tableWorkPeopleContarct = [
  {
    label: '服务商户名称',
    prop: 'merchantName',
    config: { showOverflowTooltip: true }
  },
  { label: '商户编号', prop: 'memberCode' },
  { label: '签约企业名称', prop: 'mainName' },
  { label: '签订日期', prop: 'signTime' },
  {
    label: '签约状态',
    prop: 'signState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未生成' },
          { key: 1, label: '未签约' },
          { key: 2, label: '已签约' }
        ],
        cellValue
      )
    }
  }
]

// 税源地表格
export const tablePark = [
  { label: '税源地编号', prop: 'supplierCode' },
  {
    label: '税源地名称',
    prop: 'supplierName',
    config: { showOverflowTooltip: true }
  },
  {
    label: '类型',
    prop: 'supplierType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(signType, cellValue)
    }
  },
  { label: '联系人', prop: 'linkPerson' },
  { label: '联系方式', prop: 'linkTel' },
  { label: '税源地主体', prop: 'mainName' },
  {
    label: '业务模式',
    prop: 'businessType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(parkBM, cellValue)
    }
  },
  {
    label: '认证状态',
    prop: 'authState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvAuthState, cellValue)
    }
  },
  {
    label: '授权状态',
    prop: 'authorizationState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(authorization, cellValue)
    }
  },
  { label: '备注', prop: 'remark', config: { showOverflowTooltip: true } },
  {
    label: '上线/下线',
    prop: 'isEnable',
    cmp: 'switch',
    switchConfig: {
      activeValue: 0,
      inactiveValue: 1,
      confirmMessage_0: '确认上线当前税源地？',
      confirmMessage_1: '确认下线当前税源地？下线后，该税源地不可再进行结算。'
    }
  },
  { label: '创建时间', prop: 'createTime' }
]

// 通道表格
export const tableChannel = [
  { label: '通道编号', prop: 'channelCode' },
  {
    label: '通道名称',
    prop: 'channelName',
    config: { showOverflowTooltip: true }
  },
  { label: '所属税源地', prop: 'supplierName' },
  { label: '税源地编号', prop: 'supplierCode' },
  {
    label: '发放方式',
    prop: 'grantType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvGrantType, cellValue)
    }
  },
  {
    label: '禁用/启用',
    prop: 'isEnable',
    cmp: 'switch',
    switchConfig: { activeValue: 0, inactiveValue: 1 }
  }
]

// 选择税源地表格
export const tableSelectPark = [
  { label: '税源地编号', prop: 'supplierCode' },
  {
    label: '税源地名称',
    prop: 'supplierName',
    config: { showOverflowTooltip: true }
  }
]

// 商户表格
export const tableMerchant = [
  { label: '商户号', prop: 'memberCode' },
  {
    label: '商户名称',
    prop: 'merchantName',
    config: { showOverflowTooltip: true }
  },
  { label: '登录账号', prop: 'loginName' },
  { label: '来源', prop: 'source' },
  {
    label: '认证企业',
    prop: 'mainName',
    config: { showOverflowTooltip: true, width: 250 }
  },
  {
    label: '签约类型',
    prop: 'contractMode',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvContractMode, cellValue)
    }
  },
  // {
  //   label: '业务类型',
  //   prop: 'businessType',
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(kvBM, cellValue, ' ')
  //   }
  // },
  // {
  //   label: '签约状态',
  //   prop: 'signStatus',
  //   formatter: (row, column, cellValue, index) => {
  //     return cellValue == true ? '已签约' : '未签约'
  //   }
  // },
  // {
  //   label: '商户签约方式',
  //   prop: 'signType',
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(kvSignType, cellValue)
  //   }
  // },
  // {
  //   label: "认证状态",
  //   prop: "authState",
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(kvAuthState, cellValue);
  //   },
  // },
  { label: '服务税源地', prop: 'supplierName' },
  {
    label: '商户余额',
    // prop: "merchantBalance",
    prop: 'totalBalance',
    cmp: 'tooltipFee',
    toolTipFee: [
      { prop: 'alipayBalance', label: '支付宝' },
      { prop: 'bankBalance', label: '银行卡账户' },
      { prop: 'providerBalance', label: '供应商账户' },
      { prop: 'alipayIsvBalance', label: '支付宝ISV' }
    ]
  },
  {
    label: '抵扣金余额',
    prop: 'deductionAvailableAmount',
    cmp: 'money'
  },
  { label: '创建时间', prop: 'createTime', config: { width: 150 } },
  {
    label: '禁用/启用',
    prop: 'isEnable',
    cmp: 'switch',
    switchConfig: { activeValue: '0', inactiveValue: '1' }
  }
]

// 签约表格模版
export const tableMerchantSign = [
  // { label: '签约税源地', prop: 'supplierName' },
  // {
  //   label: '税源地编号',
  //   prop: 'supplierCode',
  //   config: { showOverflowTooltip: true }
  // },
  {
    label: '类型',
    prop: 'templateType',
    config: { width: 150 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvTemplateType, cellValue)
    }
  },
  {
    label: '协议名称',
    prop: 'contractTemplateName'
  },
  {
    label: '签约方式',
    prop: 'signType',
    formatter: (row, column, cellValue, index) => {
      let text = cellValue == 2 ? '线下签约' : '线上签约'
      text += row.signMode == 1 ? '【手动】' : '【自动】'
      return text
    },
    config: { width: 150 }
  },
  // {
  //   label: '签约模式',
  //   prop: 'signMode',
  //   formatter: (row, column, cellValue, index) => {
  //     return cellValue == 1 ? '手动签约' : '自动签约'
  //   }
  // },
  { label: '签约主体', prop: 'supplierMainName' },
  // { label: '签订日期', prop: 'signTime' },
  {
    label: '签约状态',
    prop: 'signState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      // return formatStatus(kvASignState, cellValue)
      let text = ''
      if (row.signMode == 1) {
        text = `商户：${formatStatus(kvASignState, cellValue)}`
        if (cellValue == 2) text += ` ${row.signTime}`
        text += `<br/>税源地：${formatStatus(kvASignState, row.supplierSignState)}`
        if (row.supplierSignState == 2) text += ` ${row.supplierSignTime}`
      } else {
        text = formatStatus(kvASignState, cellValue)
        if (cellValue == 2) text += ` ${row.signTime}`
      }
      return text
    }
  },
  {
    label: '生效状态',
    prop: 'effectiveStatus',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(effectiveState, cellValue)
    },
    config: { width: 150 }
  },
  {
    label: '是否归档',
    prop: 'archivedStatus',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(archivedStatus, cellValue)
    },
    config: { width: 150 }
  }
]

// 合同模板
export const tableContract = [
  { label: '模板编号', prop: 'templateCode' },
  { label: '模板名称', prop: 'templateName' },
  {
    label: '模板类型',
    prop: 'templateType',
    // formatter: (row, column, cellValue, index) => {
    //   return formatStatus(kvTemplateType, cellValue)
    // }
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 1, label: '企业/个体户' },
          { key: 3, label: '个人' }
        ],
        cellValue
      )
    }
  },
  {
    label: '签约方式',
    prop: 'templateSignType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvContractMode, cellValue)
    }
  },
  { label: '是否显示', prop: 'isShow', cmp: 'switch', switchConfig: { activeValue: 0, inactiveValue: 1 } },
  { label: '备注', prop: 'remark' }
]

// 人员信息库
export const tablePersonnel = [
  { label: '姓名', prop: 'userName' },
  { label: '身份证', prop: 'idCard' },
  { label: '手机号', prop: 'mobile' },
  { label: '收款账号', prop: 'accountNumber' },
  {
    label: '效验等级',
    prop: 'vierfyLevel',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(charmStatus, cellValue)
    }
  }
]
// 白名单
export const tableWhiteList = [
  { label: '姓名', prop: 'userName' },
  { label: '身份证号', prop: 'idCard' },
  { label: '手机号', prop: 'mobile' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户编号', prop: 'memberCode' },
  { label: '添加人', prop: 'addPeople' },
  { label: '添加时间', prop: 'createTime' },
  { label: '状态', prop: 'auditState' }
]
// 黑名单
export const tableBalckList = [
  { label: '姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard' },
  { label: '手机号', prop: 'mobile' },
  { label: '所属商户', prop: 'merchantName' },
  { label: '商户编号', prop: 'memberCode' },
  { label: '备注', prop: 'remark' },
  { label: '创建时间', prop: 'createTime' }
]
// 交付物
export const tableDeliverable = [
  { label: '商户号', prop: 'memberCode' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '创客姓名', prop: 'usernName' },
  { label: '身份证号', prop: 'idCard' },
  { label: '当前月份', prop: 'currentMonth' },
  // { label: '所属企业', prop: 'beEnterprise' },
  { label: '发放金额', prop: 'grantAmount', cmp: 'money' },
  { label: '任务编号', prop: 'taskNo' },
  { label: '任务名称', prop: 'taskName' },
  {
    label: '抽取状态',
    prop: 'spotState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未抽取' },
          { key: 1, label: '已抽取' }
        ],
        cellValue
      )
    }
  },
  {
    label: '交付物状态',
    prop: 'uploadState',
    formatter: (row, column, cellValue, index) => {
      let state = formatStatus(
        [
          { key: 0, label: '待上传' },
          { key: 1, label: '已上传' }
        ],
        cellValue
      )
      let auditState = formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核中' },
          { key: 2, label: '审核通过' },
          { key: 3, label: '已退回' }
        ],
        row.delAuditState
      )

      return state + ' ' + auditState
    }
  },
  {
    label: '成功验收单状态',
    prop: 'apcUploadState',
    formatter: (row, column, cellValue, index) => {
      let state = formatStatus(
        [
          { key: 0, label: '待上传' },
          { key: 1, label: '已上传' }
        ],
        cellValue
      )
      let auditState = formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核中' },
          { key: 2, label: '审核通过' },
          { key: 3, label: '已退回' }
        ],
        row.apcAuditState
      )

      return state + ' ' + auditState
    }
  }
]
// 业务确认单
export const tableBusiness = [
  { label: '商户号', prop: 'memberCode' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '企业名称', prop: 'mainName' },
  { label: '当前月份', prop: 'dateMonth' },
  { label: '税源地', prop: 'supplierName' },
  { label: '总金额', prop: 'grantAmount', cmp: 'money' },
  {
    label: '确认方式',
    prop: 'confirmReceiptsMode',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvBusinessWayState, cellValue)
    }
  },
  { label: '确认人', prop: 'confirmPerson' },
  { label: '确认时间', prop: 'confirmTime' },
  {
    label: '状态',
    prop: 'confirmState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvBusinessState, cellValue)
    }
  }
  // {
  //   label: '上传状态',
  //   prop: 'uploadState',
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(
  //       kvBusinessUploadState,
  //       cellValue
  //     )
  //   }
  // },
  // {
  //   label: '审核状态',
  //   prop: 'auditState',
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(
  //       kvBusinessAuditState,
  //       cellValue
  //     )
  //   }
  // },
  // {
  //   label: '上传时间',
  //   prop: 'uploadTime'
  //   // formatter: (row, column, cellValue, index) => {
  //   //   return timestampToTime(cellValue);
  //   // },
  // }
]
// 完税凭证
export const tablecredentials = [
  { label: '完税编号', prop: 'taxVoucherNo' },
  { label: '商户号', prop: 'memberCode' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '税源地', prop: 'supplierName' },
  { label: '税源地编号', prop: 'supplierCode' },
  { label: '完税名称', prop: 'taxVoucherName' },
  { label: '月份', prop: 'taxVoucherDateMonth' },
  {
    label: '状态',
    prop: 'taxVoucherState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(taxState, cellValue)
    }
  }
]
// 角色
export const tableRole = [
  { label: '角色名称', prop: 'roleName', config: { width: 240 } },
  { label: '备注', prop: 'roleDesc' },
  {
    label: '状态',
    prop: 'isEenable',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvRoleEenable, cellValue)
    },
    config: { width: 120 }
  }
]

// 公告列表
export const tableNotice = [
  { label: '公告编号', prop: 'messageCode' },
  { label: '公告名称', prop: 'messageTitle' },
  // { label: '公告内容', prop: 'messageContent' },
  {
    label: '公告类型',
    prop: 'messageType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvNoticeType, cellValue)
    }
  },
  { label: '发布时间', prop: 'issueTime' },
  {
    label: '发布类型',
    prop: 'issueMode',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvNoticeIssueMode, cellValue)
    }
  },
  { label: '发布用户', prop: 'issueUserName' },
  {
    label: '状态',
    prop: 'issueState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvNoticeIssueState, cellValue)
    }
  },
  {
    label: '是否开屏',
    prop: 'isShow',
    cmp: 'switch',
    switchConfig: { activeValue: 1, inactiveValue: 0 }
  }
]
// 数据统计 - 平台数据汇总
export const tableTerrace = [
  { label: '日期', prop: 'date' },
  { cmp: 'money', label: '税后金额', prop: 'issueAmount' },
  { label: '提交笔数', prop: 'submitNum' },
  { cmp: 'money', label: '成功金额', prop: 'successAmount' },
  { label: '成功笔数', prop: 'successNum' },
  { cmp: 'money', config: { width: '70px' }, label: '成功率', prop: 'successRate' },
  { cmp: 'money', config: { width: '70px' }, label: '服务费率', prop: 'serviceTariffing' },
  { cmp: 'money', config: { width: '70px' }, label: '成本费率', prop: 'costServiceTariffing' },
  { cmp: 'money', label: '服务费', prop: 'serviceFee' },
  { label: '服务费抵扣金', prop: 'deductionAmount', cmp: 'money' },
  { label: '支付金额', prop: 'balanceAmount', cmp: 'money' },
  { cmp: 'money', label: '差额服务费', prop: 'serviceDiffFee' },
  { cmp: 'money', label: '成本费', prop: 'costServiceFee' },
  { cmp: 'money', label: '差额成本费', prop: 'costServiceDiffFee' },
  { cmp: 'money', label: '利润', prop: 'profit' },
  { label: 'GMV', prop: 'totalAmount' }
]

// 数据统计 - 商户数据汇总
export const tableMerchants = [
  { label: '日期', prop: 'date' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户编号', prop: 'memberCode' },
  { label: '税源地', prop: 'supplierName' },
  { config: { width: '70px' }, cmp: 'money', label: '税后金额', prop: 'issueAmount' },
  { config: { width: '70px' }, label: '提交笔数', prop: 'submitNum' },
  { config: { width: '70px' }, cmp: 'money', label: '成功金额', prop: 'successAmount' },
  { config: { width: '70px' }, label: '成功笔数', prop: 'successNum' },
  { config: { width: '70px' }, cmp: 'money', label: '成功率', prop: 'successRate' },
  { config: { width: '100px' }, label: '服务费抵扣金', prop: 'deductionAmount', cmp: 'money' },
  { config: { width: '70px' }, label: '支付金额', prop: 'balanceAmount', cmp: 'money' },
  { config: { width: '70px' }, cmp: 'money', label: '服务费率', prop: 'serviceTariffing' },
  { config: { width: '70px' }, cmp: 'money', label: '成本费率', prop: 'costServiceTariffing' },
  { config: { width: '70px' }, cmp: 'money', label: '服务费', prop: 'serviceFee' },
  { config: { width: '90px' }, cmp: 'money', label: '差额服务费', prop: 'serviceDiffFee' },
  { config: { width: '70px' }, cmp: 'money', label: '成本费', prop: 'costServiceFee' },
  { config: { width: '90px' }, cmp: 'money', label: '差额成本费', prop: 'costServiceDiffFee' },
  { config: { width: '70px' }, cmp: 'money', label: '利润', prop: 'profit' },
  { config: { width: '70px' }, cmp: 'money', label: 'GMV', prop: 'totalAmount' },
  { config: { width: '70px' }, cmp: 'money', label: '渠道费率', prop: 'channelTariffing' },
  { config: { width: '90px' }, cmp: 'money', label: '渠道利润率', prop: 'channelProfitTariffing' },
  { config: { width: '70px' }, cmp: 'money', label: '净利润率', prop: 'netProfitTariffing' },
  { config: { width: '70px' }, cmp: 'money', label: '渠道利润', prop: 'channelProfit' },
  { config: { width: '70px' }, cmp: 'money', label: '净利润', prop: 'netProfit' },
  { config: { width: '70px' }, label: '渠道归属', prop: 'channelName' },
  { config: { width: '100px' }, label: '商务(组织)', prop: 'empAndOrgan' }
]
// 通道列表
export const tableOrder = [
  { label: '创客姓名', prop: 'messageCode' },
  { label: '手机号', prop: 'messageCode' },
  { label: '申请时间', prop: 'messageCode' },
  { label: '商户名称', prop: 'messageCode' },
  { label: '商户编号', prop: 'messageCode' },
  { label: '任务编号', prop: 'messageCode' },
  { label: '任务名称', prop: 'messageCode' },
  { label: '任务类型', prop: 'messageCode' },
  { label: '状态', prop: 'messageCode' }
]
// 接单列表
export const tableOrderReceiving = [
  {
    prop: 'applyTime',
    label: '申请时间'
  },
  {
    prop: 'userName',
    label: '创客姓名'
  },
  {
    prop: 'mobile',
    label: '手机号'
  },
  { prop: 'taskNo', label: '任务编号' },
  { prop: 'taskName', label: '任务名称' },
  {
    prop: 'taskType',
    label: '任务类型',
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 0) return '结算任务'
      if (cellValue == 1) return '普通任务'
    }
  },
  {
    prop: 'state',
    label: '状态',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvState, cellValue)
    }
  },
  {
    prop: 'auditState',
    label: '审核状态',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvAuditState, cellValue)
    }
  }
]
// 主体列表
export const tableElectricitySign = [
  {
    prop: 'applyTime',
    label: '创客姓名'
  },
  {
    prop: 'userName',
    label: '身份证号'
  },
  {
    prop: 'mobile',
    label: '社会统一信用代码'
  },
  { prop: 'taskNo', label: '商户名称' },
  { prop: 'taskName', label: '商户编号' },
  { prop: 'taskName', label: '模板编号' },
  { prop: 'taskName', label: '签约方' },
  { prop: 'taskName', label: '生成时间' },
  { prop: 'taskName', label: '认证状态' },
  { prop: 'taskName', label: '签约状态' },
  { prop: 'taskName', label: '归档状态' }
  // {
  //   prop: 'auditState',
  //   label: '审核状态',
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(kvAuditState, cellValue)
  //   }
  // }
]
// 主体列表
export const tableMainList = [
  {
    prop: 'mainCode',
    label: '主体编号'
  },
  {
    prop: 'mainName',
    label: '企业名称'
  },
  {
    prop: 'creditCode',
    label: '社会统一信用代码'
  },
  {
    prop: 'authenState',
    label: '认证状态',
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 0) return '未认证'
      if (cellValue == 1) return '已认证'
    }
  },
  {
    prop: 'authorState',
    label: '授权状态',
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 0) return '未授权'
      if (cellValue == 1) return '已授权'
    }
  }
]
// 新交付物列表
export const tableNewDeliverable = [
  {
    prop: 'extractPlanNo',
    label: '计划编号'
  },
  {
    prop: 'extractMerchantNumber',
    label: '抽查商户'
  },
  {
    prop: 'extractPeopleNumber',
    label: '抽查创客'
  },
  { prop: 'grantAmount', cmp: 'money', label: '发放金额' },
  { prop: 'belongTime', label: '结算所属期' },
  {
    prop: 'isSpecifyScope',
    label: '抽取商家范围',
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 0) return '不指定'
      if (cellValue == 1) return '指定'
    }
  },
  { prop: 'finishDetial', label: '完成情况' },
  { prop: 'createTime', label: '创建时间' }
]
// 人员列表
export const tablePersonList = [
  { label: '商户号', prop: 'memberCode' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '创客姓名', prop: 'usernName' },
  { label: '身份证号', prop: 'idCard' },
  { label: '发放金额', prop: 'grantAmount', cmp: 'money' },
  { label: '任务编号', prop: 'taskNo' },
  { label: '任务名称', prop: 'taskName' },
  // {
  //   label: '抽取状态',
  //   prop: 'spotState',
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(
  //       [
  //         { key: 0, label: '未抽取' },
  //         { key: 1, label: '已抽取' }
  //       ],
  //       cellValue
  //     )
  //   }
  // },
  {
    label: '交付物状态',
    prop: 'uploadState',
    formatter: (row, column, cellValue, index) => {
      let state = formatStatus(
        [
          { key: 0, label: '待上传' },
          { key: 1, label: '已上传' }
        ],
        cellValue
      )
      let auditState = formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核中' },
          { key: 2, label: '审核通过' },
          { key: 3, label: '已退回' }
        ],
        row.delAuditState
      )

      return state + ' ' + auditState
    }
  },
  {
    label: '成功验收单状态',
    prop: 'apcUploadState',
    formatter: (row, column, cellValue, index) => {
      let state = formatStatus(
        [
          { key: 0, label: '待上传' },
          { key: 1, label: '已上传' }
        ],
        cellValue
      )
      let auditState = formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核中' },
          { key: 2, label: '审核通过' },
          { key: 3, label: '已退回' }
        ],
        row.apcAuditState
      )

      return state + ' ' + auditState
    }
  }
]
// 电话复合列表
export const tablephoneReview = [
  { label: '电核计划编号', prop: 'memberCode' },
  { label: '结算所属期', prop: 'merchantName' },
  { label: '商户号', prop: 'usernName' },
  { label: '商户名称', prop: 'idCard' },
  { label: '创客姓名', prop: 'grantAmount' },
  { label: '身份证号', prop: 'taskNo' },
  { label: '发放金额', prop: 'taskName', cmp: 'money' },
  { label: '任务编号', prop: 'taskName' },
  { label: '任务名称', prop: 'taskName' },
  {
    label: '跟进状态',
    prop: 'spotState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未跟进' },
          { key: 1, label: '已跟进' }
        ],
        cellValue
      )
    }
  },
  {
    label: '反馈结果',
    prop: 'spotState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '待确认' },
          { key: 1, label: '合格' },
          { key: 2, label: '不合格' }
        ],
        cellValue
      )
    }
  },
  { label: '创建时间', prop: 'taskName' }
]
// 服务费返还
export const tableService = [
  { label: '服务费返还单号', prop: 'serialNo', config: { width: 200 } },
  { label: '所属商户', prop: 'merchantName' },
  { label: '结算年月', prop: 'settleMonth' },
  { label: '抵扣金', prop: 'diffServicefee', cmp: 'money' },
  { label: '服务费变更差额', prop: 'diffServicefee', cmp: 'money' },
  {
    label: '服务费变更金额总计',
    prop: '',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return `<span>${row.diffPre} <i class="el-icon-right"></i> ${row.diffAfter}</span>`
    },
    config: { width: 150 }
  },
  { label: '发起时间', prop: 'createTime', config: { width: 150 } },
  {
    label: '发放状态',
    prop: 'state',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '待入账' },
          { key: 1, label: '已入账' }
        ],
        cellValue
      )
    }
  },
  { label: '备注', prop: 'auditRemark' }
]
// 赠送
export const tableGive = [
  { label: '业务流水号', prop: 'serialNo', config: { width: 200 } },
  { label: '所属商户', prop: 'merchantName' },
  { label: '抵扣金', prop: 'diffServicefee', cmp: 'money' },
  { label: '发起时间', prop: 'createTime', config: { width: 150 } },
  { label: '发放时间', prop: 'finishTime', config: { width: 150 } },
  {
    label: '审核状态',
    prop: 'auditState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核通过' },
          { key: 2, label: '审核不通过' }
        ],
        cellValue
      )
    }
  },
  { label: '发放说明', prop: 'applyRemark' },
  { label: '发起人', prop: 'applyer' },
  { label: '审核人', prop: 'auditor' },
  { label: '审核说明', prop: 'auditRemark' },
  {
    label: '状态',
    prop: 'state',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '待入账' },
          { key: 1, label: '已入账' }
        ],
        cellValue
      )
    }
  }
]
// 短信表格
export const tableSms = [
  { label: '短信模板分类', prop: 'smsCategory' },
  {
    label: '短信模板编号',
    prop: 'smsCode'
  },
  { label: '短信模板名称', prop: 'smsName' },
  { label: '收件人', prop: 'smsRecipients' },
  {
    label: '禁用/启用',
    prop: 'isEnable',
    cmp: 'switch',
    switchConfig: { activeValue: 0, inactiveValue: 1 }
  }
]
