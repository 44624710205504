<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <el-descriptions title="提现信息" :column="2" border :labelStyle="{ textAlign: 'right' }">
      <el-descriptions-item label="提现单号">{{ detail.withdrawNo || '无' }}</el-descriptions-item>
      <el-descriptions-item label="申请时间">{{ detail.withdrawTime || '无' }}</el-descriptions-item>
      <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
      <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
      <el-descriptions-item label="提现账户">{{ detail.accountNumber || '无' }}</el-descriptions-item>
      <el-descriptions-item label="提现金额"
        ><span v-format="'#,##0.00'">{{ detail.withdrawAmount || 0 }}</span></el-descriptions-item
      >
      <el-descriptions-item label="账户名称">{{ detail.withdrawAccountName || '无' }}</el-descriptions-item>
      <el-descriptions-item label="开户行">{{ detail.withdrawAccountBank || '无' }}</el-descriptions-item>
      <el-descriptions-item label="收款账号">{{ detail.withdrawAccountNumber || '无' }}</el-descriptions-item>
      <el-descriptions-item label="联行号">{{ detail.withdrawAccountLines || '无' }}</el-descriptions-item>
      <el-descriptions-item label="创建人">{{ detail.createUser || '无' }}</el-descriptions-item>
    </el-descriptions>
    <div style="margin-bottom: 25px"></div>
    <el-descriptions title="付款信息" :column="2" border :labelStyle="{ textAlign: 'right' }">
      <el-descriptions-item label="状态"><span v-html="status(detail.withdrawState)"></span></el-descriptions-item>
      <el-descriptions-item label="付款卡号">{{ detail.withdrawalAccount || '无' }}</el-descriptions-item>
      <el-descriptions-item label="关联流水单号">{{ detail.withdrawBackNo || '无' }}</el-descriptions-item>
      <el-descriptions-item label="第三方管理订单号">{{ detail.withdrawThirdNo || '无' }}</el-descriptions-item>
      <el-descriptions-item label="审核时间">{{ detail.auditTime || '无' }}</el-descriptions-item>
      <el-descriptions-item label="打款时间">{{ detail.withdrawFinishTime || '无' }}</el-descriptions-item>
      <el-descriptions-item label="理由">{{ detail.noPassCause || '无' }}</el-descriptions-item>
      <el-descriptions-item label="失败原因">{{ detail.withdrawDesc || '无' }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { formatStatus, kvWithdrawState } from '@/configs/formatters.js'
export default {
  data() {
    return {
      id: null,
      show: false,
      detail: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.show = true
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetWithdrawLogInfo({ Id: this.id })
      this.detail = res
    },
    // 状态
    status(val) {
      if (val) return formatStatus(kvWithdrawState, val)
      return '无'
    }
  }
}
</script>
