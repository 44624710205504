<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vtable" :labels="labels" :searchList="searchList" apiName="GetEmployeeDataStatisticsPageList" :initSearch="initSearch">
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" @click="exportData" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
    </TableView>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { downloadExcel } from '@/utils'
const searchList = [
  {
    cmp: 'dtpickers',
    label: '日期',
    prop: ['StartDate', 'EndDate']
  },
  {
    cmp: 'input',
    label: '商务名称',
    prop: 'EmployeeName'
  },
  {
    cmp: 'input',
    label: '商户名称',
    prop: 'MerchantName'
  },
  {
    cmp: 'input',
    label: '税源地名称',
    prop: 'SupplierName'
  }
]
const labels = [
  { label: '日期', prop: 'date' },
  { label: '商务', prop: 'employeeName' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '税源地', prop: 'supplierName' },
  { label: '服务费率', cmp: 'money', prop: 'serviceTariffing' },
  { label: 'GMV（元）', cmp: 'money', prop: 'gmv' },
  { label: '渠道费用（元）', cmp: 'money', prop: 'channelFee' },
  { label: '毛利（元）', cmp: 'money', prop: 'netProfit' }
]
export default {
  data() {
    return {
      btnloadDownload: false,
      labels,
      searchList,
      initSearch: {}
    }
  },
  created() {
    const lastDay = dayjs().subtract(1, 'day')
    this.initSearch = {
      StartDate: lastDay.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      EndDate: lastDay.endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    async exportData() {
      this.btnloadDownload = true
      const params = this.$refs.vtable.getParams()
      const res = await this.$api.ExportEmployeeDataStatisticsPageList(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '商务数据汇总')
    }
  }
}
</script>
