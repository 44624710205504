<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1100px">
    <!-- 内容区域 -->
    <el-divider content-position="left">基本信息</el-divider>
    <el-descriptions :column="2" :labelStyle="{ width: '200px', textAlign: 'right' }">
      <el-descriptions-item label="税源地编号">{{ detail.supplierCode || '--' }}</el-descriptions-item>
      <el-descriptions-item label="税源地名称">{{ detail.supplierName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="联系人">{{ detail.linkPerson || '--' }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{ detail.linkTel || '--' }}</el-descriptions-item>
      <el-descriptions-item label="类型"><span v-html="formatStatus(parkOptions, detail.supplierType)"></span></el-descriptions-item>
      <el-descriptions-item label="业务模式"><span v-html="formatStatus(parkBM, detail.businessType)"></span></el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">企业主体</el-divider>
    <el-descriptions :column="2" :labelStyle="{ width: '200px', textAlign: 'right' }">
      <el-descriptions-item label="认证企业">{{ detail.mainName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="社会统一信用代码">{{ detail.creditCode || '--' }}</el-descriptions-item>
      <el-descriptions-item label="营业执照照片"><ImageViewer :path="detail.businessLicense" /></el-descriptions-item>
      <el-descriptions-item label="认证状态"
        ><span v-html="formatStatus(kvIsAuthentication, detail.isAuthentication)"></span
        ><span
          v-if="detail.isAuthentication == 0"
          :style="{ color: '#216dcb', 'margin-left': '10px', cursor: 'pointer' }"
          @click="copyLink(detail.fddAuthenticationUrl)"
          >复制认证链接</span
        ></el-descriptions-item
      >
      <el-descriptions-item label="授权状态"
        ><span v-html="formatStatus(authorization, detail.isSign)"></span>
        <span
          v-if="detail.isSign == 0 && detail.isAuthentication == 1"
          :style="{ color: '#216dcb', 'margin-left': '10px', cursor: 'pointer' }"
          @click="copyLink(detail.authorizationUrl, '授权')"
          >复制授权链接</span
        >
        <span
          v-if="detail.isSign == 1"
          :style="{ color: '#216dcb', 'margin-left': '10px', cursor: 'pointer' }"
          @click="download(detail.authorizationContractUrl)"
          >下载授权书</span
        ></el-descriptions-item
      >
    </el-descriptions>
    <el-divider content-position="left" v-if="detail.supplierType == 2">开户企业</el-divider>
    <el-descriptions :column="2" :labelStyle="{ width: '200px', textAlign: 'right' }">
      <template v-if="detail.supplierType == 2">
        <el-descriptions-item label="认证企业">{{ detail.expeMainName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="社会统一信用代码">{{ detail.expeCreditCode || '--' }}</el-descriptions-item>
        <el-descriptions-item label="营业执照照片"><ImageViewer :path="detail.expeBusinessLicense" /></el-descriptions-item>
        <!-- <el-descriptions-item label="认证状态">{{ detail.expeIsAuthentication == 1 ? '已认证' : '未认证' || '--' }}</el-descriptions-item>
        <el-descriptions-item label="授权状态">{{ detail.expeIsSign || '--' }}</el-descriptions-item> -->
        <el-descriptions-item label="认证状态"
          ><span v-html="formatStatus(kvIsAuthentication, detail.expeIsAuthentication)"></span
          ><span
            v-if="detail.expeIsAuthentication == 0"
            :style="{ color: '#216dcb', 'margin-left': '10px', cursor: 'pointer' }"
            @click="copyLink(detail.expeFddAuthenticationUrl)"
            >复制认证链接</span
          ></el-descriptions-item
        >
        <el-descriptions-item label="授权状态"
          ><span v-html="formatStatus(authorization, detail.expeIsSign)"></span>
          <span
            v-if="detail.expeIsSign == 0 && detail.expeIsAuthentication == 1"
            :style="{ color: '#216dcb', 'margin-left': '10px', cursor: 'pointer' }"
            @click="copyLink(detail.expeAuthorizationUrl, '授权')"
            >复制授权链接</span
          >
          <span
            v-if="detail.expeIsSign == 1"
            :style="{ color: '#216dcb', 'margin-left': '10px', cursor: 'pointer' }"
            @click="download(detail.expeAuthorizationContractUrl)"
            >下载授权书</span
          ></el-descriptions-item
        >
      </template>
    </el-descriptions>
    <el-divider content-position="left">开户信息</el-divider>
    <el-descriptions :column="2" :labelStyle="{ width: '200px', textAlign: 'right' }">
      <template v-for="item in detail.supplierAccounts">
        <el-descriptions-item :key="item.Id" label="开户行" :span="1">{{ item.accountBank || '无' }}</el-descriptions-item>
        <el-descriptions-item :key="item.Id" label="开户名称" :span="1">{{ item.accountName || '无' }}</el-descriptions-item>
        <el-descriptions-item :key="item.Id" label="配置信息" :span="2">
          <el-input v-model="item.accountConfig" type="textarea" disabled style="width: '660px'"></el-input>
        </el-descriptions-item>
      </template>
    </el-descriptions>
    <el-divider content-position="left">其他信息</el-divider>
    <el-descriptions :column="2" :labelStyle="{ width: '200px', textAlign: 'right' }">
      <el-descriptions-item label="支持创客签约" :span="2"
        ><span
          v-html="
            formatStatus(
              [
                { key: 0, label: '否' },
                { key: 1, label: '是' }
              ],
              detail.isPeopleSign
            )
          "
        ></span
      ></el-descriptions-item>
      <el-descriptions-item label="可开票类型" :span="2">{{ invoiceList(detail.invoiceTypeList) || '无' }}</el-descriptions-item>
      <el-descriptions-item label="可开票税目" :span="2">
        <el-button v-if="id" type="primary" icon="el-icon-download" :loading="btnloadDownload" @click="exportTax">导出</el-button>
        <el-table :data="taxTable" style="width: 600px">
          <el-table-column label="税目">
            <template slot-scope="{ row, $index }">
              {{ row.baseTaxItemsName + ' * ' + row.taxItemsName }}
            </template>
          </el-table-column>
          <el-table-column label="税目状态" width="120px">
            <template slot-scope="{ row, $index }">
              {{ row.isEnable ? '已停用' : '生效' }}
            </template>
          </el-table-column>
          <el-table-column label="调整时间" width="160px">
            <template slot-scope="{ row, $index }">
              {{ row.updateTime || row.createTime || '' }}
            </template>
          </el-table-column>
        </el-table>
      </el-descriptions-item>
      <el-descriptions-item label="数据同步" :span="2">{{ detail.isSync ? '开启' : '关闭' }}</el-descriptions-item>
      <el-descriptions-item label="参数配置" :span="2"
        ><el-input v-model="detail.syncConfig" type="textarea" disabled></el-input
      ></el-descriptions-item>
      <el-descriptions-item label="备注" :span="2">{{ detail.remark || '无' }}</el-descriptions-item>
    </el-descriptions>
    <button ref="CopyBtn" class="copyBtn" :data-clipboard-text="copyText"></button>
  </el-dialog>
</template>

<script>
import {
  formatStatus,
  kvOrganizationType,
  kvIsEntrust,
  kvIsAuthentication,
  parkOptions,
  parkBM,
  kvSyncInvoiceType,
  authorization
} from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import Clipboard from 'clipboard'
import { downloadUrl, downloadExcel } from '@/utils'
export default {
  components: { ImageViewer },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      taxTable: [],
      kvOrganizationType,
      kvIsEntrust,
      kvIsAuthentication,
      authorization,
      parkOptions,
      parkBM,
      copyText: '',
      btnloadDownload: false
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.btnloadDownload = false
    },
    async open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetSupplierInfo({ supplierId: this.id })
      this.detail = res
      this.taxTable = (this.detail.supplierTaxItemList || []).sort((a, b) => a.sort - b.sort)
    },
    copy(text, name) {
      const clipboard = new Clipboard('.copyBtn')
      clipboard.on('success', () => {
        this.$notify({
          title: name + '链接已复制到剪切板',
          message: text,
          type: 'success'
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$notify({
          title: '请手动复制' + name + '链接',
          message: text,
          type: 'success'
        })
        // 释放内存
        clipboard.destroy()
      })
      this.$refs.CopyBtn.click()
    },
    copyLink(url, handleName = '认证') {
      if (url) {
        this.copyText = url
        setTimeout(() => {
          this.copy(url, handleName)
        }, 0)
      } else {
        this.$message.warning('链接不存在')
      }
    },
    async exportTax() {
      await this.$api.ExportSupplierTax({ supplierId: this.id })
      this.btnloadDownload = true
      const res = await this.$api.ExportSupplierTax({ supplierId: this.id }).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, `税源地可开票税目`)
    },
    async download(url) {
      if (url) return downloadUrl(url)
      this.$message.warning('授权书暂未生成，请稍后重试')
    },
    // 可开票类型列表
    invoiceList(list = []) {
      const newList = list.map((item) => {
        return formatStatus(kvSyncInvoiceType, item)
      })
      return newList.join('，')
    }
  }
}
</script>
