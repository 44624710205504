<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetWithoutUserPageList" :searchList="searchList" @onSwitch="onSwitch">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="220" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" @click="$refs.Edit.open(row)">编辑</el-button>
          <el-button type="text" @click="resetPwd(row)">重置密码</el-button>
          <el-button v-if="row.isEnable == 1" type="text" class="el-button-error" @click="delOne(row)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import Edit from './edit'
const tableLabels = [
  { label: '用户编号', prop: 'userCode' },
  { label: '用户名称', prop: 'userName' },
  { label: '登录账号', prop: 'loginName' },
  { label: '联系方式', prop: 'mobile' },
  { label: '角色', prop: 'roleName' },
  {
    label: '禁用/启用',
    prop: 'isEnable',
    cmp: 'switch',
    switchConfig: { activeValue: 0, inactiveValue: 1 }
  }
]
const searchList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入登录账号/用户姓名/用户手机号',
      style: { width: '300px' }
    }
  }
]
export default {
  components: { Edit },
  data() {
    return {
      tableLabels,
      searchList
    }
  },
  methods: {
    async resetPwd(item) {
      const params = { userId: item.id }
      const res = await this.$api.ResetWithoutUserPwd(params)
      this.$message.success('密码已重置为：联系方式')
    },
    async onSwitch(val, key, row, set) {
      // if (row.id == this.$store.state.user.userId) return this.$message.warning('不能禁用自己')
      if (key == 'isEnable') {
        // if (!this.$hasAccess('002002')) return this.$message.error('您暂无权限禁用/启用')
        // 禁用启用
        const params = { id: row.id }
        const res = await this.$api.EnableWithoutUserInfo(params)
        set()
      }
    },
    async delOne(item) {
      await this.$confirm(`确认删除当前用户信息？`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const res = await this.$api.DeleteWithoutUserInfo({ userId: item.id })
      this.$message.success('用户已删除')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
