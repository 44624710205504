<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetSupplierInfoPageList" :searchList="searchList" @onSwitch="onSwitch">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('000200')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">添加</el-button>
      </el-form-item>
      <!-- <el-form-item slot="btnline-item" v-if="$hasAccess('000200')">
        <ComponentFormImport url="Supplier/ImportTaxItemBySupplier" :fileSuffix="['xlsx', 'xls']" button="导入税源地税目" @uploaded="onUploaded" />
      </el-form-item> -->
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="180" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" v-if="$hasAccess('000201')" @click="$refs.Info.open(row)">查看</el-button>
          <el-button type="text" v-if="$hasAccess('000202')" @click="$refs.Edit.open(row)">编辑</el-button>
          <el-dropdown style="margin-left: 10px">
            <span class="el-dropdown-link"> ISV安全发<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="getISVSignUrl(row)">签约协议链接</el-dropdown-item>
              <el-dropdown-item @click.native="getISVSignState(row)">查看签约状态</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button type="text" v-if="$hasAccess('000203') && row.authState == 0 && row.authorizationState == 0" @click="getAuth(row)"
            >认证链接</el-button
          >
          <el-button type="text" v-if="$hasAccess('000204') && row.authState == 1 && row.authorizationState == 0" @click="getAuthorization(row)"
            >授权链接</el-button
          >
          <el-button type="text" v-if="row.authState == 1 && row.authorizationState == 1" @click="download(row)">授权书下载</el-button> -->
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()"></Edit>
    <Info ref="Info"></Info>
    <DialogImport1 ref="DialogImport1" @submit="import1Submit"></DialogImport1>
    <DialogImport2 ref="DialogImport2"></DialogImport2>
    <!-- 复制用按钮 -->
    <button ref="CopyBtn" class="copyBtn" :data-clipboard-text="copyText"></button>
  </div>
</template>
<script>
import { tablePark } from '@/configs/tables'
import { searchPark } from '@/configs/searches'
import Edit from './edit'
import Info from './info'
import Clipboard from 'clipboard'
import { downloadUrl } from '@/utils'
import ComponentFormImport from '@/components/formView/import'
import DialogImport1 from './import1'
import DialogImport2 from './import2'
export default {
  components: { Edit, Info, ComponentFormImport, DialogImport1, DialogImport2 },
  data() {
    return {
      tableLabels: tablePark,
      searchList: searchPark,
      copyText: ''
    }
  },
  methods: {
    // 表格内的切换事件
    async onSwitch(val, key, row, set) {
      if (key == 'isEnable') {
        // if (!this.$hasAccess('000301')) return this.$message.error('您暂无权限禁用/启用')
        // 禁用启用
        const params = { supplierId: row.id, isEnable: val }
        const res = await this.$api.SetSupplierEnable(params)
        set()
      }
    },
    copy(text, name) {
      this.copyText = text
      this.$nextTick(() => {
        const clipboard = new Clipboard('.copyBtn')
        clipboard.on('success', () => {
          this.$notify({
            title: name + '链接已复制到剪切板',
            message: text,
            type: 'success'
          })
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', (e) => {
          this.$notify({
            title: '请手动复制' + name + '链接',
            message: text,
            type: 'warning'
          })
          // 释放内存
          clipboard.destroy()
        })
        this.$refs.CopyBtn.click()
      })
    },
    // 认证链接获取
    async getAuth(item) {
      const res = await this.$api.GetSupplierAuthenticationShortUrl({ Id: item.id })
      this.copy(res, '认证')
    },
    // 授权链接获取
    async getAuthorization(item) {
      const res = await this.$api.GetSupplierAuthorizationShortUrl({ Id: item.id })
      this.copy(res, '授权')
    },
    // 下载
    async download(item) {
      if (item.authorizationContractUrl) return downloadUrl(item.authorizationContractUrl)
      this.$message.warning('授权书暂未生成，请稍后重试')
    },
    // 导入回调
    onUploaded(res) {
      if (res.showTaxItemsList && res.showTaxItemsList.length) {
        this.$refs.DialogImport1.open(res)
      } else {
        this.import1Submit(res.requestId, true)
      }
    },
    async import1Submit(id, nomsg) {
      const res = await this.$api.SubmitImportTaxItemBySupplier({ RequestId: id })
      if (!nomsg) this.$message.success('缺失税目补全成功')
      this.$refs.DialogImport2.open(res)
    },
    // ISV安全发 查看签约链接
    async getISVSignUrl(item) {
      const loading = this.$loading({
        text: '链接生成中……'
      })
      this.$api
        .GetAlipayISVAgreementSignUrl({ supplierId: item.id })
        .then((res) => {
          this.copy(res, 'ISV安全发签约')
        })
        .finally(() => {
          loading.close()
        })
    },
    // ISV安全发 查看签约状态
    async getISVSignState(item) {
      const res = await this.$api.GetAlipayISVAgreementSignState({ supplierId: item.id })
      this.$notify({
        title: `${item.supplierCode}-${item.supplierName}`,
        message: `ISV签约状态：${res}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #3d78fa;
  font-size: 12px;
  .el-icon-arrow-down {
    font-size: 12px;
  }
  ::v-deep .el-dropdown-menu__item {
    font-size: 12px;
  }
}
</style>
