<template>
  <el-dialog title="审核" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <el-descriptions :column="2" border :labelStyle="{ width: '120px', textAlign: 'right' }">
      <el-descriptions-item label="提现单号">{{ detail.withdrawNo || '无' }}</el-descriptions-item>
      <el-descriptions-item label="申请时间">{{ detail.withdrawTime || '无' }}</el-descriptions-item>
      <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
      <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
      <el-descriptions-item label="提现账户">{{ detail.accountNumber || '无' }}</el-descriptions-item>
      <el-descriptions-item label="提现金额"
        ><span v-format="'#,##0.00'">{{ detail.withdrawAmount || 0 }}</span></el-descriptions-item
      >
      <el-descriptions-item label="收款账号">{{ detail.withdrawAccountNumber || '无' }}</el-descriptions-item>
      <el-descriptions-item label="账户名称">{{ detail.withdrawAccountName || '无' }}</el-descriptions-item>
      <el-descriptions-item label="开户行">{{ detail.withdrawAccountBank || '无' }}</el-descriptions-item>
      <el-descriptions-item label="创建人">{{ detail.createUser || '无' }}</el-descriptions-item>
      <el-descriptions-item label="备注" :span="2">{{ detail.remark || '无' }}</el-descriptions-item>
    </el-descriptions>
    <div style="margin-bottom: 25px"></div>
    <el-form v-if="show && form.openState != 4" ref="vForm" :model="form" labelWidth="150px">
      <el-row>
        <el-col :span="13">
          <el-form-item prop="withdrawalAccount" :rules="[{ required: true, message: '请选择付款账户并查看账户余额', trigger: 'change' }]">
            <template slot="label">
              付款账户
              <el-tooltip effect="dark">
                <i class="el-icon-question" style="font-size: 1.5"></i>
                <div slot="content" style="line-height: 1.5">
                  默认账户 : 招商银行默认记账子单元(0000000000)<br />当前账户 : 招商银行商户记账子单元<br />支付宝账户 : 支付宝商户记账本
                </div>
              </el-tooltip>
              ：
            </template>
            <el-select v-model="form.withdrawalAccount" :disabled="withdrawAccountDisabled" @change="withdrawAccountChange">
              <el-option v-for="(item, index) in withdrawAccountOptions" :key="index" :label="item.accountName" :value="item.accountCode"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="实际余额："
            ><span v-format="'#,##0.00'">{{ balance || 0 }}</span></el-form-item
          >
        </el-col>
        <el-col>
          <el-form-item
            label="第三方管理订单号："
            prop="withdrawThirdNo"
            :rules="[{ required: true, message: '请输入第三方管理订单号，如果没有，请填写无', trigger: 'blur' }]"
          >
            <el-input v-model="form.withdrawThirdNo" placeholder="如果没有，请填写无"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="submit(2)" :loading="btnloadSubmitN">审核不通过</el-button>
      <el-button type="primary" @click="submit(1)" :loading="btnloadSubmit">审核通过</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formatStatus, kvWithdrawState } from '@/configs/formatters.js'
export default {
  data() {
    return {
      btnloadSubmitN: false,
      btnloadSubmit: false,
      id: null,
      show: false,
      detail: {},
      form: {},
      // 账户备选
      withdrawAccountOptions: [],
      withdrawAccountDisabled: false,
      // 余额
      balance: 0
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.form = {}
      this.withdrawAccountOptions = []
      this.withdrawAccountDisabled = false
      this.balance = 0
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.show = true
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetWithdrawLogInfo({ Id: this.id })
      this.detail = res
      this.form = {
        id: res.id,
        withdrawNo: res.withdrawNo,
        // payAccountNumber: res.payAccountNumber,
        // withdrawAccount
        openState: res.openState
      }
      this.withdrawAccountOptions = res.withdrawalAccounts || []
      if (this.withdrawAccountOptions.length == 1) {
        this.form.withdrawalAccount = this.withdrawAccountOptions[0].accountCode
        this.withdrawAccountDisabled = true
        this.getBalance()
      }
    },
    // 获取账户余额
    async getBalance() {
      const res = await this.$api.GetAccountBalanceByWithdraw({
        accountId: this.detail.accountId,
        withdrawAccount: this.form.withdrawalAccount || 0
      })
      this.balance = res.balance
    },
    // 账户改变
    withdrawAccountChange() {
      this.getBalance()
    },
    // 状态
    status(val) {
      if (val) return formatStatus(kvWithdrawState, val)
      return '无'
    },
    // submit
    async submit(auditState) {
      if (this.form.openState != 4) {
        this.$refs.vForm.validate(async (valid) => {
          if (valid) {
            this.handlerAudit(auditState)
          }
        })
      } else {
        this.handlerAudit(auditState)
      }
    },
    async handlerAudit(auditState) {
      if (auditState == 1) {
        // 审核通过
        // 判断金额
        // if (this.detail.withdrawAmount > (this.detail.balance || 0))
        //   return this.$message.error(`当前帐户余额：${this.textFormat(this.detail.balance || 0, '#,##0.00')}，账号余额不足`)
        this.btnloadSubmit = true
        const res = await this.$api.AuditWithdrawLog(Object.assign({ auditState }, this.form)).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('审核通过')
        this.$emit('submit')
        this.show = false
      } else if (auditState == 2) {
        // 审核不通过
        const { value } = await this.$prompt('', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^\S{1,200}$/,
          inputType: 'textarea',
          inputPlaceholder: '必填，请输入审核不通过理由，不超过200字',
          inputErrorMessage: '必填，请输入审核不通过理由，不超过200字'
        })
        this.btnloadSubmitN = true
        const res = await this.$api.AuditWithdrawLog(Object.assign({ auditState, noPassCause: value }, this.form)).finally(() => {
          this.btnloadSubmitN = false
        })
        this.$message.success('审核不通过')
        this.$emit('submit')
        this.show = false
      } else {
        this.$message.error('无效的操作')
      }
    }
  }
}
</script>
