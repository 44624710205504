<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vtable" :labels="labels" :searchList="searchList" apiName="GetMDataStatisticsPageList" :initSearch="initSearch">
      <el-form-item slot="btnline-item">
        <el-button v-if="$hasAccess('005901')" plain type="primary" @click="exportData" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <el-table-column label="操作" slot="table-item" width="60px">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('005900')" type="text" @click="toLink(row)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { tableMerchants } from '@/configs/tables'
import { searchMerchants } from '@/configs/searches'
import { downloadExcel } from '@/utils'
export default {
  data() {
    return {
      btnloadDownload: false,
      labels: tableMerchants,
      searchList: searchMerchants,
      initSearch: {}
    }
  },
  created() {
    const lastDay = dayjs().subtract(1, 'day')
    this.initSearch = {
      StartTime: lastDay.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      EndTime: lastDay.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      QueryTimeType: 1
    }
  },
  methods: {
    async exportData() {
      this.btnloadDownload = true
      const params = this.$refs.vtable.getParams()
      const res = await this.$api.ExportMDataStatisticsPageList(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '商户数据汇总')
    },
    toLink(row) {
      const { startTime, endTime } = row
      this.$router.push({
        name: 'workPlanDetail',
        query: {
          startTime,
          endTime
        }
      })
    }
  }
}
</script>
