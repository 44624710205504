import { Request } from '@/utils/request'
import { getUserId } from '@/utils/auth'
import { Form } from 'element-ui'

const API = {
  login: (form = {}) => {
    return Request.post('login', form)
  },
  LoginSendSmsGetPhone: (form = {}) => {
    return Request.post('System/LoginSendSmsGetPhone', form)
  },
  tableTemplate: (form = {}) => {
    const { pageSize, pageIndex } = form
    return {
      current: pageIndex || 1,
      list: [
        {
          xxa: '测试文本',
          xxb: '测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本',
          xxc: '<i>斜的字</i>',
          xxd: 1,
          xxe: 0,
          xxf: 1,
          xxg: '2021-12-08 17:44:03',
          xxh: '图片地址是：',
          xxi: '@/assets/logo.png',
          xxj: '测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本'
        }
      ],
      pageSize: pageSize || 10,
      pageTotal: 70,
      total: 695
    }
  },
  // 要素验证
  GetFddPerson: (form = {}) => {
    return Request.get('Merchant/GetFddPerson', { params: form })
  },
  // 要素验证
  GetFddCompany: (form = {}) => {
    return Request.get('Merchant/GetFddCompany', { params: form })
  },
  // 要素验证
  VerifyIngredient: (form = {}) => {
    return Request.post('System/VerifyIngredient', form)
  },
  // 共生鸟充值结果查询
  QuerySSCRechargeAccount: (form = {}) => {
    return Request.post('Finance/QuerySSCRechargeAccount', form)
  },
  // 共生鸟充值结果查询后生成充值记录
  CreateRechargeLog: (form = {}) => {
    return Request.post('Finance/CreateRechargeLog', form)
  },
  // 回单上传
  SetReceipt: (form = {}) => {
    return Request.get('Merchant/SetReceipt', { params: form })
  },
  // 电子回单压缩包列表
  GetReceiptZipPageList: (form = {}) => {
    return Request.get('Finance/GetReceiptZipPageList', { params: form })
  },
  // 生成电子回单压缩包
  BuildReceiptZip: (form = {}) => {
    return Request.post('Finance/BuildReceiptZip', form)
  },
  // 创客列表
  GetWorkPeopleQueries: (form = {}) => {
    return Request.get('WorkPeople/GetWorkPeoplePageList', { params: form })
  },
  // 创客信息
  GetWorkPeopleInfo: (form = {}) => {
    return Request.get('WorkPeople/GetWorkPeople', { params: form })
  },
  // 创客导出
  ExportWorkPeopleList: (form = {}) => {
    return Request.post('WorkPeople/ExportWorkPeopleList', form, {
      responseType: 'blob'
    })
  },
  // 获得个体户分页数据
  GetIndividualPeoplePageList: (form = {}) => {
    return Request.get('IndividualPeople/GetIndividualPeoplePageList', { params: form })
  },
  // 个体户签约
  IndividualPeopleSign: (form = {}) => {
    return Request.post('IndividualPeople/IndividualPeopleSign', form)
  },
  // 个体户结算审核列表
  GetInidvidualPlanPageList: (form = {}) => {
    return Request.get('InidvidualPlan/GetWorkPlanPageList', { params: form })
  },
  GetInidvidualPlanPageListSummaryData: (form = {}) => {
    return Request.get('InidvidualPlan/GetWorkPlanPageListSummaryData', {
      params: form
    })
  },
  // 个体户结算审核通过
  AuditPassInidvidualPlan: (form = {}) => {
    return Request.post('InidvidualPlan/AuditPassWorkPlan', form)
  },
  // 个体户结算审核不通过
  AuditNotPassInidvidualPlan: (form = {}) => {
    return Request.post('InidvidualPlan/AuditNotPassWorkPlan', form)
  },
  // 个体户结算批量审核通过
  BatchAuditPassInidvidualPlan: (form = {}) => {
    return Request.post('InidvidualPlan/BatchAuditPassWorkPlan', form)
  },
  // 个体户结算批量审核不通过
  BatchAuditNotPassInidvidualPlan: (form = {}) => {
    return Request.post('InidvidualPlan/BatchAuditNotPassWorkPlan', form)
  },
  // 个体户结算明细 - 数据汇总
  GetInidvidualPlanDetailSumData: (form = {}) => {
    return Request.get('InidvidualPlan/GetDetailSumData', { params: form })
  },
  // 个体户结算明细
  GetInidvidualPlanDetailPageList: (form = {}) => {
    return Request.get('InidvidualPlan/GetWorkDetailPageList', { params: form })
  },
  GetInidvidualPlanByDetailPageList: (form = {}) => {
    return Request.get('InidvidualPlan/GetWorkPlanByDetailPageList', {
      params: form
    })
  },
  // 个体户结算明细重发
  IndividualDetailRepay: (form = {}) => {
    return Request.post(`IndividualDetail/repay/${form.id}`)
  },
  // 个体户结算明细批量重发
  IndividualDetailRepayByBatch: (form = []) => {
    return Request.post('IndividualDetail/repay', form)
  },
  // 个体户结算明细打款失败
  IndividualDetailPayfail: (form = {}) => {
    return Request.post(`IndividualDetail/payfail/${form.id}`)
  },
  // 个体户结算明细批量打款失败
  IndividualDetailPayfailByBatch: (form = {}) => {
    return Request.post('IndividualDetail/payfail', form)
  },
  // 个体户结算明细发放记录
  GetInidvidualPlanDetailRemit: (form = {}) => {
    return Request.get(`/InidvidualPlan/detail/remit/${form.detailid || 0}`)
  },
  GetInidvidualPlanDetailPageListSummaryData: (form = {}) => {
    return Request.get('InidvidualPlan/GetWorkDetailPageListSummaryData', {
      params: form
    })
  },
  GetInidvidualPlanByDetailPageListSummaryData: (form = {}) => {
    return Request.get('InidvidualPlan/GetWorkPlanByDetailPageListSummaryData', {
      params: form
    })
  },
  // 结算导出
  ExportInidvidualPlanDetailData: (form = {}) => {
    return Request.post('InidvidualPlan/ExportDetailData', form, {
      responseType: 'blob'
    })
  },

  // 结算审核列表
  GetWorkPlanPageList: (form = {}) => {
    return Request.get('WorkPlan/GetWorkPlanPageList', { params: form })
  },
  GetWorkPlanPageListSummaryData: (form = {}) => {
    return Request.get('WorkPlan/GetWorkPlanPageListSummaryData', {
      params: form
    })
  },
  // 结算审核通过
  AuditPassWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/AuditPassWorkPlan', form)
  },
  // 结算审核不通过
  AuditNotPassWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/AuditNotPassWorkPlan', form)
  },
  // 结算批量审核通过
  BatchAuditPassWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/BatchAuditPassWorkPlan', form)
  },
  // 结算批量审核不通过
  BatchAuditNotPassWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/BatchAuditNotPassWorkPlan', form)
  },
  // 获取签约率情况-结算
  GetSignRate: (form = {}) => {
    return Request.post('WorkPeople/GetSignRate', form)
  },
  // 获取签约率情况-批量结算
  GetSignRateByBatch: (form = {}) => {
    return Request.post('WorkPeople/GetSignRateByBatch', form)
  },
  // 结算明细 - 数据汇总
  GetDetailSumData: (form = {}) => {
    return Request.get('WorkPlan/GetDetailSumData', { params: form })
  },
  // 结算明细
  GetWorkDetailPageList: (form = {}) => {
    return Request.get('WorkPlan/GetWorkDetailPageList', { params: form })
  },
  GetWorkPlanByDetailPageList: (form = {}) => {
    return Request.get('WorkPlan/GetWorkPlanByDetailPageList', {
      params: form
    })
  },
  // 结算明细重发-带提交信息
  WorkDetailRepaySubmit: (form = {}) => {
    return Request.post(`WorkDetail/reppay`, form)
  },
  // 结算明细重发
  WorkDetailRepay: (form = {}) => {
    return Request.post(`WorkDetail/repay/${form.id}`)
  },
  // 结算明细批量重发
  WorkDetailRepayByBatch: (form = []) => {
    return Request.post('WorkDetail/repay', form)
  },
  // 结算明细打款失败
  WorkDetailPayfail: (form = {}) => {
    return Request.post(`WorkDetail/payfail`, form)
  },
  // 结算明细批量打款失败
  WorkDetailPayfailByBatch: (form = {}) => {
    return Request.post('WorkDetail/payfailmulti', form)
  },
  // 结算明细发放记录
  GetWorkDetailRemit: (form = {}) => {
    return Request.get(`/WorkPlan/detail/remit/${form.detailid || 0}`)
  },
  GetWorkDetailPageListSummaryData: (form = {}) => {
    return Request.get('WorkPlan/GetWorkDetailPageListSummaryData', {
      params: form
    })
  },
  GetWorkPlanByDetailPageListSummaryData: (form = {}) => {
    return Request.get('WorkPlan/GetWorkPlanByDetailPageListSummaryData', {
      params: form
    })
  },
  // 结算导出
  ExportDetailData: (form = {}) => {
    return Request.post('WorkPlan/ExportDetailData', form, {
      responseType: 'blob'
    })
  },
  // 获取结算回执
  GetReceiptInfo: (form = {}) => {
    return Request.get('WorkPlan/GetReceiptInfo', { params: form })
  },
  // 退汇记录
  GetFinanceReturnLogPageList: (form = {}) => {
    return Request.get('Finance/GetFinanceReturnLogPageList', { params: form })
  },
  // 退汇记录导出
  ExportReturnLogList: (form = {}) => {
    return Request.post('Finance/ExportReturnLogList', form, {
      responseType: 'blob'
    })
  },
  // 退汇订单
  GetFinanceReturnPageList: (form = {}) => {
    return Request.get('Finance/GetFinanceReturnPageList', { params: form })
  },
  // 获取结算单明细添加退汇
  GetWorkDetailByAddReturn: (form = {}) => {
    return Request.get('Finance/GetWorkDetailByAddReturn', { params: form })
  },
  // 添加退汇
  AddFinanceReturn: (form = {}) => {
    return Request.post('Finance/AddFinanceReturn', form)
  },
  // 充值记录
  GetRechargeLogPageList: (form = {}) => {
    return Request.get('Finance/GetRechargeLogPageList', { params: form })
  },
  // 充值详情
  GetRechargeLogInfo: (form = {}) => {
    return Request.post('Finance/GetRechargeLogInfo', form)
  },
  // 异名汇款列表
  GetRechargeExceptionPageList: (form = {}) => {
    return Request.get('Finance/GetRechargeExceptionPageList', { params: form })
  },
  // 异名汇款详情
  GetRechargeException: (form = {}) => {
    return Request.get('Finance/GetRechargeException', { params: form })
  },
  // 处理异名汇款
  ProcessingRechargeException: (form = {}) => {
    return Request.post('Finance/ProcessingRechargeException', form)
  },
  // 确认上账
  InAlipayAccountBalance: (form = {}) => {
    return Request.post('Finance/InAlipayAccountBalance', form)
  },
  // 上账失败
  InAlipayAccountBalanceFail: (form = {}) => {
    return Request.post('Finance/InAlipayAccountBalanceFail', form)
  },
  // 确认批量上账
  BatchInAlipayAccountBalance: (form = {}) => {
    return Request.post('Finance/BatchInAlipayAccountBalance', form)
  },
  // 导出充值列表
  ExportRechargeLogList: (form = {}) => {
    return Request.post('Finance/ExportRechargeLogList', form, {
      responseType: 'blob'
    })
  },
  // 内部划转列表
  GetDepositInsidePageList: (form = {}) => {
    return Request.get('Finance/GetDepositInsidePageList', { params: form })
  },
  // 添加内部划转
  AddDepositInside: (form = {}) => {
    return Request.post('Finance/AddDepositInside', form)
  },
  // 获取商户账户信息
  GetMerchantAccountInfoList: (form = {}) => {
    return Request.post('Merchant/GetMerchantAccountInfoList', form)
  },
  // 导出内部划转列表
  ExportDepositInside: (form = {}) => {
    return Request.post('Finance/ExportDepositInside', form, {
      responseType: 'blob'
    })
  },
  // 支付宝汇款列表
  GetTransferAlipayPageList: (form = {}) => {
    return Request.get('Finance/GetTransferAlipayPageList', { params: form })
  },
  // 导出支付宝汇款列表
  ExportTransferAlipay: (form = {}) => {
    return Request.post('Finance/ExportTransferAlipay', form, {
      responseType: 'blob'
    })
  },
  // 添加支付宝转账
  AddTransferAlipay: (form = {}) => {
    return Request.post('Finance/AddTransferAlipay', form)
  },
  // 确认支付宝转账
  SubmitTransferAlipay: (form = {}) => {
    return Request.post('Finance/SubmitTransferAlipay', form)
  },
  // 获取合并账户商户
  GetMergeMerchant: (form = {}) => {
    return Request.post('Merchant/GetMergeMerchant', form)
  },
  // 获取商户的合并账户
  GetMergeAccountByMerchant: (form = {}) => {
    return Request.post('Merchant/GetMergeAccountByMerchant', form)
  },
  // 支出记录
  GetDisburseLogPageList: (form = {}) => {
    return Request.get('Finance/GetDisburseLogPageList', { params: form })
  },
  // 提现记录
  GetWithdrawLogPageList: (form = {}) => {
    return Request.get('Finance/GetWithdrawLogPageList', { params: form })
  },
  // 提现记录详情
  GetWithdrawLogInfo: (form = {}) => {
    return Request.get('Finance/GetWithdrawLogInfo', { params: form })
  },
  // 获取商户账户余额
  GetAccountBalanceByWithdraw: (form = {}) => {
    return Request.post('Finance/GetAccountBalanceByWithdraw', form)
  },
  // 审核提现记录
  AuditWithdrawLog: (form = {}) => {
    return Request.post('Finance/AuditWithdrawLog', form)
  },
  // 导出提现数据
  ExportWithdrawData: (form = {}) => {
    return Request.post('Finance/ExportWithdrawData', form, {
      responseType: 'blob'
    })
  },
  // FP提现待办列表
  GetFPWorkPlanPageList: (form = {}) => {
    return Request.get('FPWorkPlan/GetFPWorkPlanPageList', { params: form })
  },
  // FP批量提现预设
  GetFPWorkPlanWithdraw: (form = {}) => {
    return Request.post('FPWorkPlan/GetFPWorkPlanWithdraw', form)
  },
  // FP批量提现预设列表
  GetFPWorkPlanSumByMerchant: (form = {}) => {
    return Request.get('FPWorkPlan/GetFPWorkPlanSumByMerchant', { params: form })
  },
  // FP批量提现
  SaveFPWorkPlanSumByMerchant: (form = {}) => {
    return Request.post('FPWorkPlan/SaveFPWorkPlanSumByMerchant', form)
  },
  // 回单
  GetReceiptPlanPageList: (form = {}) => {
    return Request.get('WorkPlan/GetReceiptPlanPageList', { params: form })
  },
  // 回单详情
  GetReceiptDetailPageList: (form = {}) => {
    return Request.get('WorkPlan/GetReceiptDetailPageList', { params: form })
  },
  // 回单导入
  ImportReceipt: (form = {}) => {
    return Request.post('WorkPlan/ImportReceipt', form)
  },
  // 系统-用户管理
  GetOperationUserPageList: (form = {}) => {
    return Request.get('System/GetOperationUserPageList', { params: form })
  },
  // 系统-用户管理-添加
  AddOperationUser: (form = {}) => {
    return Request.post('System/AddOperationUser', form)
  },
  // 系统-用户管理-编辑
  EditOperationUser: (form = {}) => {
    return Request.post('System/EditOperationUser', form)
  },
  // 系统-用户管理-重置密码
  ResetOperationUserPwd: (form = {}) => {
    return Request.post('System/ResetOperationUserPwd', form)
  },
  // 系统-数据平台用户管理
  GetWithoutUserPageList: (form = {}) => {
    return Request.get('System/GetWithoutUserPageList', { params: form })
  },
  // 系统-数据平台用户管理-详情
  GetWithoutUserInfo: (form = {}) => {
    return Request.get('System/GetWithoutUserInfo', { params: form })
  },
  // 系统-数据平台用户管理-角色信息
  GetWithoutUserRoleList: (form = {}) => {
    return Request.get('System/GetWithoutUserRoleList', { params: form })
  },
  // 系统-数据平台用户管理-添加
  AddWithoutUserInfo: (form = {}) => {
    return Request.post('System/AddWithoutUserInfo', form)
  },
  // 系统-数据平台用户管理-编辑
  EditWithoutUserInfo: (form = {}) => {
    return Request.post('System/EditWithoutUserInfo', form)
  },
  // 系统-数据平台用户管理-启用禁用
  EnableWithoutUserInfo: (form = {}) => {
    return Request.post('System/EnableWithoutUserInfo', form)
  },
  // 系统-数据平台用户管理-删除
  DeleteWithoutUserInfo: (form = {}) => {
    return Request.post('System/DeleteWithoutUserInfo', form)
  },
  // 系统-数据平台用户管理-重置密码
  ResetWithoutUserPwd: (form = {}) => {
    return Request.post('System/ResetWithoutUserPwd', form)
  },
  // 获取商户开关
  MerchantSwitchQuery: (form = {}) => {
    return Request.get('Merchant/switch/query', { params: form })
  },
  // 保存商户开关
  MerchantSwitchSave: (form = {}) => {
    return Request.post('Merchant/switch/save', form)
  },
  // 系统-用户管理-禁用启用
  DisabledOperationUser: (form = {}) => {
    return Request.post('System/DisabledOperationUser', form)
  },
  // 系统设置-保存 SetAgeLimit SetLoginProhibit SaveSingleParameter
  SaveSetSystem: (api, form = {}) => {
    return Request.post('System/' + api, form)
  },
  // 系统配置-获取全局发放额度配置
  GetSingleParameter: (form = {}) => {
    return Request.get(`/System/GetSingleParameter/${form}`)
  },
  // 添加运营商 (Auth)
  AddTenant: (form = {}) => {
    return Request.post('Tenante/add', form)
  },
  // 分页查询运营商 (Auth)
  GetTenantPageList: (form = {}) => {
    return Request.post('Tenante/query/page', form)
  },
  // 切换运营商状态 (Auth)
  ToggleTenant: (form = {}) => {
    return Request.post(`Tenante/toggle/${form.id}`)
  },

  // 修改用户密码
  UpdateOperationUserPwd: (form = {}) => {
    return Request.post('System/UpdateOperationUserPwd', Object.assign({ userId: getUserId() }, form))
  },
  // 系统-用户管里-删除
  DeleteOperationUser: (form = {}) => {
    return Request.post('System/DeleteOperationUser', form)
  },
  // 获取开票审核退汇分页数据 InvoiceId
  GetAuditInvoiceReturnList: (form = {}) => {
    return Request.get('BillApply/GetAuditInvoiceReturnList', { params: form })
  },
  // 获取开票审核查看的退汇分页数据 InvoiceId
  GetInvoiceDetailReturnList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceDetailReturnList', { params: form })
  },
  // 开票审核
  GetInvoicePageList: (form = {}) => {
    return Request.get('BillApply/GetInvoicePageList', { params: form })
  },
  // 开票审核统计
  GetInvoiceAuditSummaryAmount: (form = {}) => {
    return Request.get('BillApply/GetInvoiceAuditSummaryAmount', { params: form })
  },
  // 开票记录审核统计
  GetInvoiceLogSummaryAmount: (form = {}) => {
    return Request.get('BillApply/GetInvoiceLogSummaryAmount', { params: form })
  },
  // 资金流水记录
  GetFinanceFundPageList: (form = {}) => {
    return Request.get('Finance/GetFinanceFundPageList', { params: form })
  },
  // 资金流水统计
  GetFinanceFundTypeSummary: (form = {}) => {
    return Request.get('Finance/GetFinanceFundTypeSummary', { params: form })
  },
  GetBillInfoDetail: (form = {}) => {
    return Request.get('BillApply/BillInfoDetail', { params: form })
  },
  // 发票审核
  AuditInvoice: (form = {}) => {
    return Request.post('BillApply/AuditInvoice', form)
  },
  // 导出发票审核
  ExportInvoice: (form = {}) => {
    return Request.post('BillApply/ExportInvoice', form, {
      responseType: 'blob'
    })
  },
  // 获取开票详情结算单分页数据
  GetInvoiceDetailPlanPageList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceDetailPlanPageList', {
      params: form
    })
  },
  // 获取开票详情发票分页数据
  GetInvoiceDetailReceiptPageList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceDetailReceiptPageList', {
      params: form
    })
  },
  GetBillByInvoiceDetailReceiptPageList: (form = {}) => {
    return Request.get('BillApply/GetBillByInvoiceDetailReceiptPageList', {
      params: form
    })
  },
  // 开票记录
  GetInvoiceLogPageList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceLogPageList', { params: form })
  },
  // 退回开票信息
  ReturnInvoice: (form = {}) => {
    return Request.post('BillApply/ReturnInvoice', form)
  },
  // 开票记录详情
  GetBillingInfoDetail: (form = {}) => {
    return Request.get('BillApply/BillingInfoDetail', { params: form })
  },
  // 一级税目列表
  GetTaxItemsParentList: (form = {}) => {
    return Request.get('BillApply/GetTaxItemsParentList', { params: form })
  },
  GetEditByTaxItemsParentList: (form = {}) => {
    return Request.get('BillApply/GetEditByTaxItemsParentList', {
      params: form
    })
  },
  // 二级税目列表
  GetTaxItemsPageList: (form = {}) => {
    return Request.post('BillApply/GetTaxItemsPageList', form)
  },
  // 修改税目信息
  UpdateTaxItem: (form = {}) => {
    return Request.post('BillApply/UpdateTaxItem', form)
  },
  // 删除税目信息
  DeleteTaxItem: (form = {}) => {
    return Request.post('BillApply/DeleteTaxItem', form)
  },
  // 完税证明
  GetTaxVoucherPageList: (form = {}) => {
    return Request.post('BillApply/GetTaxVoucherPageList', form)
  },
  // 删除完税凭证
  DeleteTaxVoucher: (form = {}) => {
    return Request.post('BillApply/DeleteTaxVoucher', form)
  },
  // 获取认证短链接
  GetSupplierAuthenticationShortUrl: (form = {}) => {
    return Request.get('Supplier/GetSupplierAuthenticationShortUrl', {
      params: form
    })
  },
  // 获取授权短链接
  GetSupplierAuthorizationShortUrl: (form = {}) => {
    return Request.get('Supplier/GetSupplierAuthorizationShortUrl', {
      params: form
    })
  },
  // 根据主键获取通道
  GetChannelCodeName: (form = {}) => {
    return Request.post('channel/GetChannelCodeName', form)
  },
  // 获取税源地发放通道
  GetSupplierChannelInfo: (form = {}) => {
    return Request.post('Supplier/GetSupplierChannelInfo', form)
  },
  GetAddMerchantBySupplierChannelInfo: (form = {}) => {
    return Request.post('Supplier/GetAddMerchantBySupplierChannelInfo', form)
  },
  GetSetBySupplierChannelInfo: (form = {}) => {
    return Request.post('Supplier/GetSetBySupplierChannelInfo', form)
  },
  // 获取抵扣金配置信息
  GetMerchantMonthly: (form = {}) => {
    return Request.get('Merchant/GetMerchantMonthly', {
      params: form
    })
  },
  // 保存抵扣金配置信息
  SaveMerchantMonthly: (form = {}) => {
    return Request.post('Merchant/SaveMerchantMonthly', form)
  },
  // 配置管理-开通通道
  MerchantOpenChannel: (form = {}) => {
    return Request.post('Merchant/OpenChannel', form)
  },
  // 配置管理-更改通道
  MerchantChangeChannel: (form = {}) => {
    return Request.post('Merchant/ChangeChannel', form)
  },
  // 开通通道
  OpenChannel: (form = {}) => {
    return Request.post('Supplier/OpenChannel', form)
  },
  // 保存上传发票
  SaveInvoice: (form = {}) => {
    return Request.post('BillApply/SaveInvoice', form)
  },
  // 开票完成(必须在调用保存上传发票接口返回成功后,调用此接口)
  FinishInvoice: (form = {}) => {
    return Request.post('BillApply/FinishInvoice', form)
  },
  // 保存发票快递信息
  SaveInvoiceExpress: (form = {}) => {
    return Request.post('BillApply/SaveInvoiceExpress', form)
  },
  // 获取税源地分页数据
  GetSupplierInfoPageList: (form = {}) => {
    return Request.get('Supplier/GetSupplierInfoPageList', { params: form })
  },
  // 添加税源地信息
  AddSupplierInfo: (form = {}) => {
    return Request.post('Supplier/AddSupplierInfo', form)
  },
  // 修改税源地信息
  EditSupplierInfo: (form = {}) => {
    return Request.post('Supplier/EditSupplierInfo', form)
  },
  // 发送税源地认证短信
  SendSupplierAuthMsg: (form = {}) => {
    return Request.post('Supplier/SendSupplierAuthMsg', form)
  },
  // 获取税源地详细信息
  GetSupplierInfo: (form = {}) => {
    return Request.get('Supplier/GetSupplierInfo', { params: form })
  },
  GetEditBySupplierInfo: (form = {}) => {
    return Request.get('Supplier/GetEditBySupplierInfo', { params: form })
  },
  // 税源地上下线
  SetSupplierEnable: (form = {}) => {
    return Request.post('Supplier/SetSupplierEnable', form)
  },
  // 获取任务分页信息
  GetTaskManagePageList: (form = {}) => {
    return Request.get('Task/GetTaskManagePageList', { params: form })
  },
  // 获取任务详细信息
  GetTaskManageInfo: (form = {}) => {
    return Request.get('Task/GetTaskManageInfo', { params: form })
  },
  // 保存系统参数设置
  SaveSystemParameter: (form = {}) => {
    return Request.post('System/SaveSystemParameter', form)
  },
  // 获取系统参数列表
  GetSystemParameterList: (form = {}) => {
    return Request.post('System/GetSystemParameterList')
  },
  // 通道信息列表分页数据
  GetWorkChannelPageList: (form = {}) => {
    return Request.get('channel/GetWorkChannelPageList', { params: form })
  },
  // 添加通道信息
  AddWorkChannel: (form = {}) => {
    return Request.post('channel/AddWorkChannel', form)
  },
  // 编辑通道信息
  EditWorkChannel: (form = {}) => {
    return Request.post('channel/EditWorkChannel', form)
  },
  // 根据主键查询获取通道详情信息
  GetWorkChannelDetaliInfo: (form = {}) => {
    return Request.get('channel/GetWorkChannelDetaliInfo', { params: form })
  },
  GetEditByWorkChannelDetailInfo: (form = {}) => {
    return Request.get('channel/GetEditByWorkChannelDetailInfo', {
      params: form
    })
  },
  // 启用/禁用通道信息
  EnableWorkChannel: (form = {}) => {
    return Request.post('channel/EnableWorkChannel', form)
  },
  // 获取商户分页信息
  GetMerchantInfoPageList: (form = {}) => {
    return Request.get('Merchant/GetMerchantInfoPageList', { params: form })
  },
  // 个体户商户列表
  GetIndividualMerchantInfoPageList: (form = {}) => {
    return Request.get('Merchant/GetIndividualMerchantInfoPageList', { params: form })
  },
  // 添加商户信息
  AddMerchantInfo: (form = {}) => {
    return Request.post('Merchant/AddMerchantInfo', form)
  },
  // 编辑商户信息
  EditMerchantInfo: (form = {}) => {
    return Request.post('Merchant/EditMerchantInfo', form)
  },
  // 获取税源地编号及名称信息
  GetSupplierInfoCodeName: (form = {}) => {
    return Request.post('Supplier/GetSupplierInfoCodeName', form)
  },
  // 获取主体信息
  GetBusinessMain: (form = {}) => {
    return Request.post('Merchant/GetBusinessMain', form)
  },
  GetSupplierByBusinessMain: (form = {}) => {
    return Request.post('Merchant/GetSupplierByBusinessMain', form)
  },
  // 获取商户配置信息
  GetMerchantInfo: (form = {}) => {
    return Request.get('Merchant/GetMerchantInfo', { params: form })
  },
  GetBatchAuditByMerchantInfo: (form = {}) => {
    return Request.get('Merchant/GetBatchAuditByMerchantInfo', {
      params: form
    })
  },
  // 获取税目列表(树状结构)
  GetTaxItemsLevelList: (form = {}) => {
    return Request.get('Merchant/GetTaxItemsLevelList', { params: form })
  },
  // 税源地税目导出
  ExportSupplierTax: (form = {}) => {
    return Request.post('Supplier/ExportSupplierTax', form, {
      responseType: 'blob'
    })
  },
  // 业务确认单详情
  GetConfirmReceipts: (form = {}) => {
    return Request.get('Merchant/GetConfirmReceipts', { params: form })
  },
  // 保存商户配置信息
  SaveMerchantConfig: (form = {}) => {
    return Request.post('Merchant/SaveMerchantConfig', form)
  },
  // 获取商户对接参数
  GetMerchantApiConfig: (form = {}) => {
    return Request.get('Merchant/GetMerchantApiConfig', { params: form })
  },
  // 保存商户对接参数
  SaveMerchantApi: (form = {}) => {
    return Request.post('Merchant/SaveMerchantApi', form)
  },
  // 添加主体税源地签约信息
  SaveMainSupplierInfo: (form = {}) => {
    return Request.post('Merchant/SaveMainSupplierInfo', form)
  },
  // 获取主体签约分页列表信息
  GetMainSupplierInfoPageList: (form = {}) => {
    return Request.post('Merchant/GetMainSupplierInfoPageList', form)
  },
  // 获取商户账户信息
  GetMerchantAccount: (form = {}) => {
    return Request.post('Merchant/GetMerchantAccount', form)
  },
  // 获取商户税目信息
  GetMerchantTaxList: (form = {}) => {
    return Request.post('Merchant/GetMerchantTaxList', form)
  },
  // 获取商户阶梯费率信息
  GetMerchantTariffingList: (form = {}) => {
    return Request.post('Merchant/GetMerchantTariffingList', form)
  },
  // 启用/禁用商户信息
  EnableMerchantInfo: (form = {}) => {
    return Request.post('Merchant/EnableMerchantInfo', form)
  },
  // 获取商户详细信息
  GetMerchantInfoDetail: (form = {}) => {
    return Request.get('Merchant/GetMerchantInfoDetail', { params: form })
  },
  // 发放商户税源地签约短信
  SendMainSupperSignMsg: (form = {}) => {
    return Request.post('Merchant/SendMainSupperSignMsg', form)
  },
  // 切换合同显示
  ShowAndHideContractTemplate: (form = {}) => {
    return Request.post(`/System/ShowAndHideContractTemplate`, form)
  },
  // 合同模板
  GetContractList: (form = {}) => {
    return Request.get('System/GetContractTemplatePageList', { params: form })
  },
  // 获取个人合同模板字段
  WorkerContractTemplateFields: (form = {}) => {
    return Request.get('System/WorkerContractTemplateFields', { params: form })
  },
  // 新增模板
  PushContractAddItem: (form = {}) => {
    return Request.post('System/AddContractTemplate', form)
  },
  // 修改模板
  PushContractModifyItem: (form = {}) => {
    return Request.post('System/EditContractTemplate', form)
  },
  // 删除模板项
  delConteactItem: (form = {}) => {
    return Request.post('System/DeleteContractTemplate', form)
  },
  // 获取模板字段列表
  GetConteactTemplateList: (form = {}) => {
    return Request.post('System/GetContractTemplateFieldList', form)
  },
  GetEditByContractTemplateFieldList: (form = {}) => {
    return Request.post('System/GetEditByContractTemplateFieldList', form)
  },
  // 获取关键字列表
  GetContractTemplateKeywordList: (form = {}) => {
    return Request.post('System/GetContractTemplateKeywordList', form)
  },
  GetEditByContractTemplateKeywordList: (form = {}) => {
    return Request.post('System/GetEditByContractTemplateKeywordList', form)
  },
  // 人员信息库列表
  GetWorkPeopleVerifyPageList: (form = {}) => {
    return Request.get('WorkPeople/GetWorkPeopleVerifyPageList', {
      params: form
    })
  },
  // 人员信息库列表
  PutWorkPeopleVerify: (form = {}) => {
    return Request.post('WorkPeople/UpdateWorkPeopleVerify', form)
  },
  // 根据签约类型获取合同下拉列表
  GetTemplateListBySignType: (form = {}) => {
    return Request.get('Contract/signtypeddl/maker')
  },
  // 填充模板
  GetFillTemplateList: (form = {}) => {
    return Request.get('System/GetContractTemplateSelectList')
  },
  // 获取商户options
  GetMerchantInfoCodeAndName: (form = {}) => {
    return Request.get('Merchant/GetMerchantInfoCodeAndName', { params: form })
  },

  // 获取填充字段
  GetFillFieldList: (form = {}) => {
    return Request.post('System/GetContractTemplateFieldList', form)
  },
  // 上传合同
  UploadContract: (form = {}) => {
    return Request.post('Merchant/UploadContractTemplate', form)
  },
  // 获取河马的商户开票税目
  QueryMerchantHmTaxCode: (form = {}) => {
    return Request.post('Merchant/QueryMerchantHmTaxCode', form)
  },
  // 置税源地
  SettingSupplier: (form = {}) => {
    return Request.post('Merchant/SettingSupplier', form)
  },

  // 获取税源地税目
  GetSupplierTaxList: (form = {}) => {
    return Request.post('Supplier/GetSupplierTaxList', form)
  },
  // 导入税源地税目
  ImportTaxItemBySupplier: (form = {}) => {
    return Request.post('Supplier/ImportTaxItemBySupplier', form)
  },
  // 导入税目信息提交
  SubmitImportTaxItemBySupplier: (form = {}) => {
    return Request.post('Supplier/SubmitImportTaxItemBySupplier', form)
  },
  // 导入税目税源地调整确认
  ConfirmImportTaxItemBySupplier: (form = {}) => {
    return Request.post('Supplier/ConfirmImportTaxItemBySupplier', form)
  },

  // 置商户密码
  ResetMerchantUserPwd: (form = {}) => {
    return Request.post('Merchant/ResetMerchantUserPwd', form)
  },
  // 取交付物分页信息
  GetTaskDeliverablePageList: (form = {}) => {
    return Request.get('Task/GetTaskDeliverablePageList', {
      params: form
    })
  },
  // 取业务确认单分页信息
  GetConfirmReceiptsPageList: (form = {}) => {
    return Request.get('Merchant/GetConfirmReceiptsPageList', {
      params: form
    })
  },
  // 出业务确认单
  ExportConfirmReceipts: (form = {}) => {
    return Request.post('Merchant/Export', form, {
      responseType: 'blob'
    })
  },
  // 回业务确认单
  ReturnConfirmReceipts: (form = {}) => {
    return Request.get('Merchant/ReturnConfirmReceipts', {
      params: form
    })
  },
  // 新生成业务确认单
  AfreshGenerateConfirmReceipts: (form = {}) => {
    return Request.post('Merchant/AfreshGenerateConfirmReceipts', form)
  },
  // 验重复手机号
  CheckMerchantInfo: (form = {}) => {
    return Request.post('Merchant/CheckMerchantInfo', form)
  },
  // 取白名单详情信息
  GetSystemWhiteInfo: (form = {}) => {
    return Request.get('System/GetSystemWhiteInfo', {
      params: form
    })
  },
  // 名单列表
  GetSystemWhitePageList: (form = {}) => {
    return Request.get('System/GetSystemWhitePageList', {
      params: form
    })
  },
  // 加白名单
  AddSystemWhite: (form = {}) => {
    return Request.post('System/AddSystemWhite', form)
  },
  // 除白名单
  DeleteSystemWhite: (form = {}) => {
    return Request.post('System/DeleteSystemWhite', form)
  },
  // 取黑名单详情信息
  GetSystemBlackInfo: (form = {}) => {
    return Request.get('System/GetSystemBlackInfo', {
      params: form
    })
  },
  // 名单列表
  GetSystemBlackPageList: (form = {}) => {
    return Request.get('System/GetSystemBlackPageList', {
      params: form
    })
  },
  // 加黑名单
  AddSystemBlack: (form = {}) => {
    return Request.post('System/AddSystemBlack', form)
  },
  // 除黑名单
  DeleteSystemBlack: (form = {}) => {
    return Request.post('System/DeleteSystemBlack', form)
  },
  // 获取任务场景列表
  GetTaskScenestype: (form = {}) => {
    return Request.get('Task/ddl/scenestype', { params: form })
  },
  // 任务审核
  AuditTaskHandle: (form = {}) => {
    return Request.post('Task/ResetAuditTask', form)
  },
  // 任务税目数据源
  GetSelectMerchantTaxId: (form = {}) => {
    return Request.get('Task/GetSelectMerchantTaxId', { params: form })
  },
  // 支出金额汇总
  GetExpenditureAmountStatistics: (form = {}) => {
    return Request.get('Finance/GetExpenditureAmountStatistics', {
      params: form
    })
  },
  // 充值金额汇总
  GetRechargeAmountStatistics: (form = {}) => {
    return Request.get('Finance/GetRechargeAmountStatistics', {
      params: form
    })
  },
  // 完税凭证列表
  GetTaxInfoReceipt: (form = {}) => {
    return Request.get('TaxVoucher/GetSupplierInfoPageList', { params: form })
  },
  // 完税凭证导入
  UploadFileUrl: (form = {}) => {
    return Request.post('TaxVoucher/UploadFileUrl', form)
  },
  // 完税凭证导出数据详情
  ExportTaxDetailData: (form = {}) => {
    return Request.post('TaxVoucher/ExportDetailData', form, {
      responseType: 'blob'
    })
  },
  // 发送获取创客敏感数据短信验证码
  SendWpVerifySMS: (form = {}) => {
    return Request.post('WorkPeople/VerifySendSMS', form)
  },
  // 通过短信验证码获取创客敏感数据
  GetWpVerify: (form = {}) => {
    return Request.post('WorkPeople/GetWorkPeopleDetailByVerifyCode', form)
  },
  // 角色管理
  GetSystemRolePageList: (form = {}) => {
    return Request.get('System/GetSystemRolePageList', { params: form })
  },
  // 添加角色
  AddSystemRole: (form = {}) => {
    return Request.post('System/AddSystemRole', form)
  },
  // 修改角色
  EditSystemRole: (form = {}) => {
    return Request.post('System/EditSystemRole', form)
  },
  // 更改角色状态
  SetSystemRoleEenable: (form = {}) => {
    return Request.post('System/SetSystemRoleEenable', form)
  },
  // 获取角色管理下拉框
  GetSystemRoleSelectList: (form = {}) => {
    return Request.get('System/GetSystemRoleSelectList', { params: form })
  },
  // 获取菜单权限列表
  GetMenuRoleFunList: (form = {}) => {
    return Request.get('System/GetMenuRoleFunList', { params: form })
  },
  // 获取商户菜单权限列表
  GetMerchantMenuRoleFunList: (form = {}) => {
    return Request.post('System/GetMerchantMenuRoleFunList', form)
  },
  // 商户角色管理1
  GetMerchantSystemRolePageList: (form = {}) => {
    return Request.get('System/GetMerchantSystemRolePageList', {
      params: form
    })
  },
  // 获取商户角色管理详情
  GetMerchantSystemRoleInfo: (form = {}) => {
    return Request.get('System/GetMerchantSystemRoleInfo', {
      params: form
    })
  },
  // 添加商户角色1
  AddMerchantSystemRole: (form = {}) => {
    return Request.post('System/AddMerchantSystemRole', form)
  },
  // 修改商户角色1
  EditMerchantSystemRole: (form = {}) => {
    return Request.post('System/EditMerchantSystemRole', form)
  },
  // 更改商户角色状态1
  SetMerchantSystemRoleEenable: (form = {}) => {
    return Request.post('System/SetMerchantSystemRoleEenable', form)
  },
  // 获取角色对应的菜单功能列表
  GetRoleMenuFunList: (form = {}) => {
    return Request.get('System/GetRoleMenuFunList', { params: form })
  },
  // 获取homecode税目列表
  GetTaxByHmcode: (form = {}) => {
    return Request.get('Merchant/GetTaxByHmcode', { params: form })
  },
  // 交付物退回
  TaskDeliverableBack: (form = {}) => {
    return Request.post('Task/TaskDeliverableBack', form)
  },
  // 公告列表
  GetSystemMessagePageList: (form = {}) => {
    return Request.get('SystemMessage/GetSystemMessagePageList', { params: form })
  },
  // 添加公告
  AddMessage: (form = {}) => {
    return Request.post('SystemMessage/AddMessage', form)
  },
  // 修改公告
  UpdateMessage: (form = {}) => {
    return Request.post('SystemMessage/UpdateMessage', form)
  },
  // 删除公告
  RemoveMessage: (form = {}) => {
    return Request.post('SystemMessage/RemoveMessage', form)
  },
  // 查看公告
  GetMessageById: (form = {}) => {
    return Request.get('SystemMessage/GetMessageById', { params: form })
  },
  // 发布公告
  PublishMessageById: (form = {}) => {
    return Request.post('SystemMessage/PublishMessageById', form)
  },
  // 修改公告开屏和关屏
  UpdateMessageIsShow: (form = {}) => {
    return Request.post('SystemMessage/UpdateMessageIsShow', form)
  },
  // 关键词搜索税源地列表
  GetSupplierInfoByKeyWord: (form = {}) => {
    return Request.get('Supplier/GetSupplierInfoByKeyWord', { params: form })
  },
  // 根据协议类型获取合同模板
  GetContractTemplateByTypePageList: (form = {}) => {
    return Request.post('Merchant/GetContractTemplateByTypePageList', form)
  },
  // 签约管理 - 添加签约
  AddBusinessMainSupplier: (form = {}) => {
    return Request.post('Merchant/AddBusinessMainSupplier', form)
  },
  // 签约管理 - 作废
  DisuseBusinessMainSupplierInfo: (form = {}) => {
    return Request.post('Merchant/DisuseBusinessMainSupplierInfo', form)
  },
  // 签约管理 - 归档
  PigeonholeBusinessMainSupplierInfo: (form = {}) => {
    return Request.post('Merchant/PigeonholeBusinessMainSupplierInfo', form)
  },
  // 签约管理 - 删除
  DeleteBusinessMainSupplierInfo: (form = {}) => {
    return Request.post('Merchant/DeleteBusinessMainSupplierInfo', form)
  },
  // 新增任务
  AddTaskManage: (form = {}) => {
    return Request.post('Task/AddTaskManage', form)
  },
  StopTask: (form = {}) => {
    return Request.post('Task/StopTask', form)
  },
  SpotCheck: (form = {}) => {
    return Request.post('Task/SpotCheck', form)
  },
  CanelSpotCheck: (form = {}) => {
    return Request.post('Task/CanelSpotCheck', form)
  },
  BatchSpotCheck: (form = {}) => {
    return Request.post('Task/BatchSpotCheck', form)
  },
  UpdateTaskTax: (form = {}) => {
    return Request.post('Task/UpdateTaskTax', form)
  },
  // 签约白名单审核
  AuditSystemWhite: (form = {}) => {
    return Request.post('System/AuditSystemWhite', form)
  },
  // 商务数据汇总
  GetEmployeeDataStatisticsPageList: (form = {}) => {
    return Request.get('DataStatistics/GetEmployeeDataStatisticsPageList', { params: form })
  },
  // 平台数据汇总
  GetDataStatisticsPageList: (form = {}) => {
    return Request.get('DataStatistics/GetDataStatisticsPageList', { params: form })
  },
  // 商户数据汇总
  GetMDataStatisticsPageList: (form = {}) => {
    return Request.get('DataStatistics/GetMDataStatisticsPageList', { params: form })
  },
  // 合并账户支付宝统计
  GetMergeAlipayAccountPageList: (form = {}) => {
    return Request.get('DataStatistics/GetMergeAlipayAccountPageList', { params: form })
  },
  // 合并账户支付宝统计累计
  GetMergeAlipayAccountStatisticsData: (form = {}) => {
    return Request.get('DataStatistics/GetMergeAlipayAccountStatisticsData', { params: form })
  },
  // 合并账户支付宝统计详情列表
  GetMergeAlipayAccountDetailPageList: (form = {}) => {
    return Request.get('DataStatistics/GetMergeAlipayAccountDetailPageList', { params: form })
  },
  // 获取差额提醒列表
  GetMergeAlipayDiffWarn: (form = {}) => {
    return Request.get('DataStatistics/GetMergeAlipayDiffWarn', { params: form })
  },
  // 保存差额提醒列表
  SaveMergeAlipayDiffWarn: (form = {}) => {
    return Request.post('DataStatistics/SaveMergeAlipayDiffWarn', form)
  },
  // 导出商务汇总
  ExportEmployeeDataStatisticsPageList: (form = {}) => {
    return Request.post('DataStatistics/ExportEmployeeDataStatisticsPageList', form, { responseType: 'blob' })
  },
  // 导出平台汇总
  ExportDataStatisticsPageList: (form = {}) => {
    return Request.post('DataStatistics/ExportDataStatisticsPageList', form, { responseType: 'blob' })
  },
  // 导出商户汇总
  ExportMDataStatisticsPageList: (form = {}) => {
    return Request.post('DataStatistics/ExportMDataStatisticsPageList', form, { responseType: 'blob' })
  },
  // 导出合并账户支付宝统计
  ExportMergeAlipayAccountPageList: (form = {}) => {
    return Request.post('DataStatistics/ExportMergeAlipayAccountPageList', form, { responseType: 'blob' })
  },
  // 务列表
  GetTaskNameValueList: (form = {}) => {
    return Request.get('Task/GetTaskNameValueList', { params: form })
  },
  // 商户配置列表
  GetMerchantConfigPageList: (form = {}) => {
    return Request.post('Merchant/GetMerchantConfigPageList', form)
  },
  // 获取商户配置详情
  GetMerchantConfigInfo: (form = {}) => {
    return Request.get('Merchant/GetMerchantConfigInfo', { params: form })
  },
  // 获取商户配置税目
  GetMerchantConfigTaxList: (form = {}) => {
    return Request.post('Merchant/GetMerchantConfigTaxList', form)
  },
  // 获取商户配置阶梯费率
  GetMerchantConfigTariffingList: (form = {}) => {
    return Request.post('Merchant/GetMerchantConfigTariffingList', form)
  },
  // 获取商户配置盖章关键字
  GetMerchantConfigKeywordList: (form = {}) => {
    return Request.post('Merchant/GetMerchantConfigKeywordList', form)
  },
  // 商户列表获取商户
  GetMerchantListBySearch: (form = {}) => {
    return Request.get('Merchant/GetMerchantListBySearch', { params: form })
  },
  // 商户列表获取税源地
  GetSupplierListBySearch: (form = {}) => {
    return Request.get('Merchant/GetSupplierListBySearch', { params: form })
  },
  // 根据关键词获取税源地信息不包含已下线税源地 (Auth)
  GetSupplierListBySearchNoEnable: (form = {}) => {
    return Request.get('Merchant/GetSupplierListBySearchNoEnable', { params: form })
  },
  // 新增商户配置
  AddMerchantConfig: (form = {}) => {
    return Request.post('Merchant/AddMerchantConfig', form)
  },
  // 保存商户配置信息
  SaveMerchantConfigInfo: (form = {}) => {
    return Request.post('Merchant/SaveMerchantConfigInfo', form)
  },
  // 应用商户配置信息
  ApplyMerchantConfigInfo: (form = {}) => {
    return Request.post('Merchant/ApplyMerchantConfigInfo', form)
  },
  // 获取商户配置对应的通道信息
  GetMerchantConfigAccountInfo: (form = {}) => {
    return Request.post('Merchant/GetMerchantConfigAccountInfo', form)
  },
  // 切换合并账户
  SwitchMergeAccount: (form = {}) => {
    return Request.post('Merchant/SwitchMergeAccount', form)
  },
  // 户列表
  GetMerchantNameValueList: (form = {}) => {
    return Request.get('Task/GetMerchantNameValueList', { params: form })
  },
  // 单列表
  GetReceiveTaskManagePageList: (form = {}) => {
    return Request.get('Task/GetReceiveTaskManagePageList', { params: form })
  },
  // 单详情
  GetReceiveTaskInfo: (form = {}) => {
    return Request.get('Task/GetReceiveTaskInfo', { params: form })
  },
  // 付物详情
  GetTaskDeliverable: (form = {}) => {
    return Request.get('Task/GetTaskDeliverable', { params: form })
  },
  // 下载成功验收单模板
  DownloadAcceptanceNoteFile: (form = {}) => {
    return Request.post('Task/DownloadAcceptanceNoteFile', form, { responseType: 'blob' })
  },
  // 导出资金流水数据
  ExportFinanceFundData: (form = {}) => {
    return Request.post('Finance/ExportFinanceFundData', form, { responseType: 'blob' })
  },
  // 下载业务确认单模板前置校验
  ConfirmReceiptsTemplateCheck: (form = {}) => {
    return Request.post('Merchant/ConfirmReceiptsTemplateCheck', form)
  },
  // 下载业务确认单模板
  DownloadConfirmReceiptsFile: (form = {}) => {
    return Request.post('Merchant/DownloadConfirmReceiptsFile', form, { responseType: 'blob' })
  },
  // 交付物审核通过
  AuditPassDeliverable: (form = {}) => {
    return Request.post('Task/AuditPassDeliverable', form)
  },
  // 交付物审核不通过
  AuditNotPassDeliverable: (form = {}) => {
    return Request.post('Task/AuditNotPassDeliverable', form)
  },
  // 验收单审核通过
  AuditPassAcceptance: (form = {}) => {
    return Request.post('Task/AuditPassAcceptance', form)
  },
  // 验收单审核不通过
  AuditNotPassAcceptance: (form = {}) => {
    return Request.post('Task/AuditNotPassAcceptance', form)
  },
  // 验收单审核不通过
  SetTaskDeliverable: (form = {}) => {
    return Request.post('System/SetTaskDeliverable', form)
  },
  // 禁止发放时间
  SetSystemForbidTime: (form = {}) => {
    return Request.post('System/SetSystemForbidTime', form)
  },
  // 短信模板列表
  GetSmsParameterAllList: (form = {}) => {
    return Request.get('System/GetSmsParameterAllList', { params: form })
  },
  // 短信模板列表
  GetSmsTemplateListByDropDown: (form = {}) => {
    return Request.get('System/GetSmsTemplateListByDropDown', { params: form })
  },
  // 限制发放发放时间
  SetSystemForbidPeriodsTime: (form = {}) => {
    return Request.post('System/SetSystemForbidPeriodsTime', form)
  },
  // 业务确认单审核通过
  AuditPassConfirmReceipts: (form = {}) => {
    return Request.post('Merchant/AuditPassConfirmReceipts', form)
  },
  // 业务确认单审核不通过
  AuditNotPassConfirmReceipts: (form = {}) => {
    return Request.post('Merchant/AuditNotPassConfirmReceipts', form)
  },
  // 监高保存
  SaveMerchantBoard: (form = {}) => {
    return Request.post(`/Merchant/SaveMerchantBoard`, form)
  },
  // 换税源地
  SwitchMerchantConfig: (form = {}) => {
    return Request.post(`/Merchant/SwitchMerchantConfig`, form)
  },
  // 董监高获取
  GetMerchantBoardList: (form = {}) => {
    return Request.get('Merchant/GetMerchantBoardList', { params: form })
  },
  // 校验并获取处理中的结算单
  CheckGetBeingWorkPlan: (form = {}) => {
    return Request.get('Merchant/CheckGetBeingWorkPlan', { params: form })
  },
  // 校验并获取不为零的账户
  CheckGetIsNotZeroAccount: (form = {}) => {
    return Request.get('Merchant/CheckGetIsNotZeroAccount', { params: form })
  },
  // 获取税源地
  GetSupplierListByMerchantId: (form = {}) => {
    return Request.get('Merchant/GetSupplierListByMerchantId', { params: form })
  },
  // 获取配置信息
  GetMerchantConfigCode: (form = {}) => {
    return Request.get('Merchant/GetMerchantConfigCode', { params: form })
  },
  // 税目列表
  GetTaxListByMerchantConfigId: (form = {}) => {
    return Request.get('Merchant/GetTaxListByMerchantConfigId', { params: form })
  },
  // 主体列表
  GetBusinessMainPageList: (form = {}) => {
    return Request.get('BusinessMain/GetBusinessMainPageList', { params: form })
  },
  // 添加主体
  AddBusinessMain: (form = {}) => {
    return Request.post('BusinessMain/AddBusinessMain', form)
  },
  // 主体信息下拉列表
  GetBusinessMainCodeAndNameList: (form = {}) => {
    return Request.get('BusinessMain/GetBusinessMainCodeAndNameList', { params: form })
  },
  // 主体信息授权状态查询
  GetBusinessMainAuthorizationState: (form = {}) => {
    return Request.get('BusinessMain/GetBusinessMainAuthorizationState', { params: form })
  },
  // 计划列表
  GetTaskDeliverableExtractPlanPageList: (form = {}) => {
    return Request.get('Task/GetTaskDeliverableExtractPlanPageList', { params: form })
  },
  // 人员名单列表
  GetTaskDeliverableExtractPlanDetail: (form = {}) => {
    return Request.get('Task/GetTaskDeliverableExtractPlanDetail', { params: form })
  },
  // 获取抽取详情
  GetExtractPlanDetail: (form = {}) => {
    return Request.get('Task/GetExtractPlanDetail', { params: form })
  },
  // 结算单统计金额
  GetWorkPlanAuditAmount: (form = {}) => {
    return Request.post('WorkPlan/GetWorkPlanAuditAmount', form)
  },
  // 添加计划
  AddTaskDeliverableExtractPlan: (form = {}) => {
    return Request.post('Task/AddTaskDeliverableExtractPlan', form)
  },
  // 重发转账记录
  ReissueServiceCharge: (form = {}) => {
    return Request.post('WorkPlan/ReissueServiceCharge', form)
  },
  // 下载发票模板
  DownloadBillApplyFile: (form = {}) => {
    return Request.post('BillApply/DownloadBillApplyFile', form, { responseType: 'blob' })
  },
  // 分页抵扣金查询
  queryDeduction: (form = {}) => {
    return Request.post('Deduction/query/page', form)
  },
  // 发起赠送
  handselDeduction: (form = {}) => {
    return Request.post('Deduction/handsel', form)
  },
  // 审核
  auditDeduction: (form = {}) => {
    return Request.post('Deduction/audit', form)
  },
  // 重新发起
  reapplyDeduction: (form = {}) => {
    return Request.post('Deduction/reapply', form)
  },
  // 删除抵扣金
  deleteDeduction: (id) => {
    return Request.post(`Deduction/delete/${id}`)
  },
  // 审核充值记录
  AuditPassRechargeLog: (form = {}) => {
    return Request.post('Finance/AuditPassRechargeLog', form)
  },
  // 审核失败充值记录
  AuditNotPassRechargeLog: (form = {}) => {
    return Request.post('Finance/AuditNotPassRechargeLog', form)
  },
  // 审核用获取充值主体商户账户列表 OperationType为1时获取账户信息
  GetRechargeMerchantAccountList: (form = {}) => {
    return Request.get('Finance/GetRechargeMerchantAccountList', { params: form })
  },
  // 通道启用禁用
  DisabledChannel: (form = {}) => {
    return Request.post('Merchant/DisabledChannel', form)
  },
  // 切换充值记录生成方式
  SwitchGenerationMode: (form = {}) => {
    return Request.post('Merchant/SwitchGenerationMode', form)
  },
  // 更换充值帐户通道
  ChangeAccountChannel: (form = {}) => {
    return Request.post('Merchant/ChangeAccountChannel', form)
  },
  // 拉取董监高列表
  getDongJiangaoData: (form = {}) => {
    return Request.get('Merchant/PullBusinessMainBoardListByMerchantId', { params: form })
  },
  // 失效黑名单信息并添加白名单
  lostAndWhiteList: (form = {}) => {
    return Request.post('System/LostSystemBlackAndAddSystemWhite', form)
  },
  // 失效黑名单信息
  confirmLost: (form = {}) => {
    return Request.post('System/LostSystemBlack', form)
  },
  // 获取人员要素校验批次分页列表
  GetVerifyPeoplePList: (form = {}) => {
    return Request.get('WorkPeople/GetVerifyPeoplePlanPageList', { params: form })
  },
  // 获取校验抽查人数
  GetCheckCount: (form = {}) => {
    return Request.get('WorkPeople/GetVerifyCount', { params: form })
  },
  // 生成校验批次
  CreatedVerifyPlan: (form = {}) => {
    return Request.post('WorkPeople/GenerateVerifyPeoplePlan', form)
  },
  // 一键校验
  OneKeyCheck: (form = {}) => {
    return Request.post('WorkPeople/VerifyPeoplePlan', form)
  },
  // 导出校验结果
  ExportResult: (form = {}) => {
    return Request.post('WorkPeople/ExportVerifyPeopleResult', form, { responseType: 'blob' })
  },
  // 获取开票登记列表
  GetInvoiceRegisterList: (form = {}) => {
    return Request.get('BillApply/GetBillRegistrationList', { params: form })
  },
  // 新增开票登记
  AddInvoiceRegister: (form = {}) => {
    return Request.post('BillApply/AddBillRegistration', form)
  },
  // 编辑开票登记列表
  EditInvoiceRegister: (form = {}) => {
    return Request.post('BillApply/EditBillRegistration', form)
  },
  // 获取开票登记详情
  getInvoiceRegisterInfo: (form = {}) => {
    return Request.get('BillApply/GetBillRegistrationInfo', { params: form })
  },
  // 下载结算单
  DownloadProfitShareTemplate: (form = {}) => {
    return Request.post('ProfitShare/DownloadProfitShareTemplate', form, { responseType: 'blob' })
  },
  // 导出利润计算器
  ExportProfitShareCalculatorData: (form = {}) => {
    return Request.post('ProfitShare/ExportProfitShareCalculatorData', form, { responseType: 'blob', timeout: 60000 })
  },
  // 获取所有的开票税目
  getAllTaxListData: (form = {}) => {
    return Request.get('BillApply/GetAllTaxItemsList', { params: form })
  },
  // 发送短信
  SendVerifyCode: (form = {}) => {
    return Request.get('Message/SendVerifyCode', { params: form })
  },
  // 检查验证码
  CheckVerifyCode: (form = {}) => {
    return Request.get('Message/CheckVerifyCode', { params: form })
  },
  // 重新认证
  reauth: (form = {}) => {
    return Request.post('BusinessMain/reauth', form)
  },
  // 短信模板
  GetSmsTemplatePageList: (form = {}) => {
    return Request.get('System/GetSmsTemplatePageList', { params: form })
  },
  // 短信模板详情
  GetSmsTemplate: (form = {}) => {
    return Request.get('System/GetSmsTemplate', { params: form })
  },
  // 短信模板启用
  EnableSmsTemplate: (form = {}) => {
    return Request.post('System/EnableSmsTemplate', form)
  },
  // 短信模板添加
  AddSmsTemplate: (form = {}) => {
    return Request.post('System/AddSmsTemplate', form)
  },
  // 短信模编辑
  EditSmsTemplate: (form = {}) => {
    return Request.post('System/EditSmsTemplate', form)
  },
  // 获取数据字典列表
  GetSystemDataDicList: (form = {}) => {
    return Request.get('System/GetSystemDataDicList', { params: form })
  },
  // 获取商务信息分页列表
  GetEmployeeInfoPageList: (form = {}) => {
    return Request.get('System/GetEmployeeInfoPageList', { params: form })
  },
  // 获取商务信息简要列表
  GetEmployeeInfoList: (form = {}) => {
    return Request.get('System/GetEmployeeInfoList', { params: form })
  },
  // 获取商务信息
  GetEmployeeInfo: (form = {}) => {
    return Request.get('System/GetEmployeeInfo', { params: form })
  },
  // 添加商务信息
  AddEmployeeInfo: (form = {}) => {
    return Request.post('System/AddEmployeeInfo', form)
  },
  // 编辑商务信息
  EditEmployeeInfo: (form = {}) => {
    return Request.post('System/EditEmployeeInfo', form)
  },
  // 获取渠道信息下拉列表
  GetChannelInfoDropDownList: (form = {}) => {
    return Request.get('System/GetChannelInfoDropDownList', { params: form })
  },
  // 获取渠道信息分页列表
  GetChannelInfoPageList: (form = {}) => {
    return Request.get('System/GetChannelInfoPageList', { params: form })
  },
  // 获取渠道信息简要列表
  GetChannelInfoList: (form = {}) => {
    return Request.get('System/GetChannelInfoList', { params: form })
  },
  // 获取渠道信息
  GetChannelInfo: (form = {}) => {
    return Request.get('System/GetChannelInfo', { params: form })
  },
  // 添加渠道信息
  AddChannelInfo: (form = {}) => {
    return Request.post('System/AddChannelInfo', form)
  },
  // 编辑渠道信息
  EditChannelInfo: (form = {}) => {
    return Request.post('System/EditChannelInfo', form)
  },
  // 获取ISV安全发协议签约链接
  GetAlipayISVAgreementSignUrl: (form = {}) => {
    return Request.post('Supplier/GetAlipayISVAgreementSignUrl', form)
  },
  // 获取ISV安全发协议签约链接
  GetAlipayISVAgreementSignState: (form = {}) => {
    return Request.post('Supplier/GetAlipayISVAgreementSignState', form)
  }
}

export default API
