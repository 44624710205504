<template>
  <el-dialog :title="!id ? '添加' : '编辑'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="formLabels" :slotIdxList="[0]" :formConfig="{ labelWidth: '120px' }">
      <template #form-items="{ idx, form }">
        <el-form-item v-if="id && idx === 0" label="用户编号" prop="userId">
          <el-input v-model="form.userCode" disabled></el-input>
        </el-form-item>
      </template>
    </FormView>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { regular } from '@/configs/validate'
const formLabels = [
  {
    cmp: 'input',
    label: '用户名称',
    prop: 'userName',
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '登录账号',
    prop: 'loginName',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        { pattern: regular.enNum, message: regular.enNumMsg, trigger: 'blur' }
      ]
    },
    disabled: `form.userId`
  },
  {
    cmp: 'input',
    label: '联系方式',
    prop: 'mobile',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { maxlength: 11 }
  },
  {
    cmp: 'select',
    label: '关联角色',
    prop: 'roleIds',
    options: [],
    config: {
      multiple: true,
      style: { width: '100%' }
    },
    itemConfig: { rules: [{ required: true, message: '必选', trigger: 'change' }] }
  },
  {
    cmp: 'radio',
    label: '税源地限制',
    prop: 'isAllSupplier',
    options: [
      { key: 0, label: '全部税源地' },
      { key: 1, label: '指定税源地' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择税源地限制', trigger: 'change' }]
    }
  },
  {
    cmp: 'select',
    label: '指定税源地',
    prop: 'supplierIds',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择指定税源地', trigger: 'change' }]
    },
    config: {
      placeholder: '请输入税源地名称',
      multiple: true,
      filterable: true,
      style: { width: '100%' }
    },
    show: 'form.isAllSupplier==1'
  },
  {
    cmp: 'radio',
    label: '商户限制',
    prop: 'isAllMerchant',
    options: [
      { key: 0, label: '全部商户' },
      { key: 1, label: '指定商户' },
      { key: 2, label: '指定渠道' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户限制', trigger: 'change' }]
    }
  },
  {
    cmp: 'select',
    label: '指定商户',
    prop: 'merchantIds',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择指定商户', trigger: 'change' }]
    },
    config: {
      placeholder: '请输入商户编号/商户名称',
      multiple: true,
      filterable: true,
      style: { width: '100%' }
    },
    show: 'form.isAllMerchant==1'
  },
  {
    cmp: 'select',
    label: '指定渠道',
    prop: 'channelIds',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择指定渠道', trigger: 'change' }]
    },
    config: {
      placeholder: '请输入渠道名称',
      multiple: true,
      filterable: true,
      style: { width: '100%' }
    },
    show: 'form.isAllMerchant==2'
  }
]
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      formLabels,
      detail: {},
      roleIds: [],
      supplierIds: [],
      merchantIds: [],
      channelIds: []
    }
  },
  mounted() {},
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      this.getRoles()
      this.getSupplierList()
      this.getMerchantList()
      this.getChannelList()
      this.show = true
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.getDetail()
      } else {
        // 添加
        this.$nextTick(() => {
          this.$refs.vForm.fillData({ isAllSupplier: 0, isAllMerchant: 0 })
        })
      }
    },
    // 获取用户详情
    async getDetail() {
      const res = await this.$api.GetWithoutUserInfo({ id: this.id })
      this.detail = res
      const { roles, suppliers, merchants, isAllMerchant, ...other } = res
      const roleIds = roles.map((it) => it.roleId)
      const supplierIds = suppliers.map((it) => it.businessId)
      const merchantIds = isAllMerchant == 1 ? merchants.map((it) => it.businessId) : []
      const channelIds = isAllMerchant == 2 ? merchants.map((it) => it.businessId) : []
      this.$nextTick(() => {
        this.$refs.vForm.fillData({ ...other, isAllMerchant, roleIds, supplierIds, merchantIds, channelIds })
      })
    },
    // 获取税源地列表
    async getSupplierList() {
      const res = await this.$api.GetSupplierInfoByKeyWord()
      // 处理数据结构并赋值
      this.formLabels.find((item) => item.prop == 'supplierIds').options = (res.list || []).map((item) => {
        return {
          label: item.supplierName,
          key: item.id
        }
      })
    },
    // 获取商户列表
    async getMerchantList() {
      const res = await this.$api.GetMerchantInfoCodeAndName()
      // 处理数据结构并赋值
      this.formLabels.find((item) => item.prop == 'merchantIds').options = (res.list || []).map((item) => {
        return {
          label: item.merchantName,
          key: item.merchantId
        }
      })
    },
    // 获取渠道列表
    async getChannelList() {
      const res = await this.$api.GetChannelInfoDropDownList()
      // 处理数据结构并赋值
      this.formLabels.find((item) => item.prop == 'channelIds').options = (res.list || []).map((item) => {
        return {
          label: item.channelName,
          key: item.channelId
        }
      })
    },
    // 获取角色列表
    async getRoles() {
      const res = await this.$api.GetWithoutUserRoleList()
      this.formLabels.find((item) => item.prop == 'roleIds').options = (res.list || []).map((item) => {
        return {
          label: item.roleName,
          key: item.roleId
        }
      })
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      // 处理参数
      const roleIds = (params.roleIds || []).map((it) => {
        return {
          roleId: it,
          roleName: this.formLabels.find((item) => item.prop == 'roleIds').options.find((item) => item.key == it)?.label
        }
      })
      const merchantIds =
        params.isAllMerchant == 1
          ? (params.merchantIds || []).map((it) => {
              return {
                businessId: it,
                businessName: this.formLabels.find((item) => item.prop == 'merchantIds').options.find((item) => item.key == it)?.label
              }
            })
          : params.isAllMerchant == 2
          ? (params.channelIds || []).map((it) => {
              return {
                businessId: it,
                businessName: this.formLabels.find((item) => item.prop == 'channelIds').options.find((item) => item.key == it)?.label
              }
            })
          : []
      const supplierIds = (params.supplierIds || []).map((it) => {
        return {
          businessId: it,
          businessName: this.formLabels.find((item) => item.prop == 'supplierIds').options.find((item) => item.key == it)?.label
        }
      })
      if (this.id) {
        // 编辑
        const res = await this.$api.EditWithoutUserInfo({ ...params, roleIds, merchantIds, supplierIds }).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('编辑成功')
      } else {
        // 添加
        const res = await this.$api.AddWithoutUserInfo({ ...params, roleIds, merchantIds, supplierIds }).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
