<template>
  <el-dialog :title="(!id ? '新增' : '编辑') + '角色'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="750px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="fromLabels"></FormView>
    <div class="dialog-wrap" style="height: 220px" v-if="show">
      <el-tree ref="vTree" :props="treeProps" :data="tree" show-checkbox node-key="funCode"> </el-tree>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { kvRoleEenable, kvIsAllMerchant } from '@/configs/formatters'
const fromLabels = [
  {
    cmp: 'input',
    label: '角色名称',
    prop: 'roleName',
    itemConfig: {
      rules: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'radio',
    label: '角色状态',
    prop: 'isEenable',
    options: kvRoleEenable,
    itemConfig: {
      rules: [{ required: true, message: '请选择角色状态', trigger: 'blur' }]
    }
  },
  {
    cmp: 'radio',
    label: '适用范围',
    prop: 'isAllMerchant',
    options: kvIsAllMerchant,
    itemConfig: {
      rules: [{ required: true, message: '请选择适用范围', trigger: 'blur' }]
    }
  },
  {
    cmp: 'select',
    label: '指定商户',
    prop: 'merchantIds',
    options: [],
    show: `form.isAllMerchant==1`,
    itemConfig: {
      rules: [{ required: true, message: '请选择指定商户', trigger: 'blur' }]
    },
    config: {
      placeholder: '请输入商户编号/商户名称查找',
      multiple: true,
      filterable: true,
      style: { width: '100%' }
    }
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'roleDesc',
    config: { type: 'textarea', maxlength: 200 }
  },
  { label: '权限' }
]
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      fromLabels,
      detail: {},
      treeProps: {
        label(data, node) {
          return data.funName || data.menuName
        },
        children: 'lists'
      },
      tree: []
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {
        isEenable: 0,
        isAllMerchant: 0
      }
      this.tree = []
    },
    open(obj) {
      this.clear()
      this.getMerchantOptions()
      this.getList()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.show = true
        this.getDetail()
        this.getMeList()
      } else {
        // 添加
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.fillData(this.detail)
        })
      }
    },
    async getDetail() {
      const { isEnable, isAllMerchantUse, ...res } = await this.$api.GetMerchantSystemRoleInfo({
        RoleId: this.id
      })
      this.detail = { isEenable: isEnable, isAllMerchant: isAllMerchantUse, ...res }
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
      })
    },
    // label字段添加
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.forEach((item) => {
        if (item.prop === 'merchantIds') {
          item.options = v
        }
      })
    },
    async getList() {
      const res = await this.$api.GetMerchantMenuRoleFunList()
      const list = res.list || []
      // 处理树结构
      this.tree = this.handlerList(list)
    },
    handlerList(list = []) {
      return list.map((item) => {
        if (!item.funCode) item.funCode = item.menuCode
        if (item.lists && item.lists.length) {
          item.lists = this.handlerList(item.lists)
        }
        return item
      })
    },
    async getMeList() {
      const res = await this.$api.GetRoleMenuFunList({ roleId: this.id })
      const cheked = (res.list || []).map((item) => {
        // funCode和menuCode相同时，判断是否为子节点
        if (item.funCode == item.menuCode) {
          if (
            res.list.find((it) => {
              return it.menuCode == item.menuCode && it.funCode != it.menuCode
            })
          )
            return ''
        }
        if (item.funCode) return item.funCode
        return ''
      })
      this.$refs.vTree.setCheckedKeys(cheked)
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()

      this.btnloadSubmit = true
      // 获取并处理树结构数据
      const treeList = this.$refs.vTree.getCheckedNodes(true)
      const systemRoleMenuFunEntities = treeList.map((item) => {
        const { funCode, menuCode } = item
        return {
          roleId: this.id || null,
          funCode,
          menuCode
        }
      })

      if (this.id) {
        // 编辑
        const res = await this.$api.EditMerchantSystemRole(Object.assign({ systemRoleMenuFunEntities }, params)).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('编辑成功')
      } else {
        // 添加
        const res = await this.$api.AddMerchantSystemRole(Object.assign({ systemRoleMenuFunEntities }, params)).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
